import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styles from './StreamCreator.module.css';
import { AdminContext } from '../../AdminContext';
import { Helmet } from 'react-helmet-async';
import Map, { Marker } from 'react-map-gl';
import mapboxgl from 'mapbox-gl';

import axios from 'axios';
import 'mapbox-gl/dist/mapbox-gl.css'; // Import Mapbox CSS

const categories = {
  News: [
    'News',
    'Breaking News',
    'Weather',
    'Live Events',
    'Weather',
    'Storm Watch',
    'Sports',
    'Festivals',
    'Parades',
    'Protests',
    'Conferences',
    'Christmas',
    'New Year’s Eve',
    'Rolling Cams'
  ],
  Tours: [
    'Walking Tour',
    'Driving Tour',
    'Drone Tour',
    'Historical Tour',
    'Food Tour',
    'Virtual Reality Tour',
    'City Tours',
    'Nature Tours',
    'Cultural Tours'
  ],
  Urban: [
    'Capital City',
    'City',
    'Village',
    'Town',
    'Urban',
    'Skylines',
    'Nightlife',
    'Harbours',
    'Train Stations',
    'Railways',
    'Island',
    'Airports',
    'Street Markets',
    'Suburbs',
    'Urban Parks',
    'Shopping Districts',
    'Bar',
    'Barbershop',
    'Hotels & Resorts',
    'Traffic',
    'Construction'
  ],
  Nature: [
    'Wildlife',
    'Animals',
    'Bird Feeders',
    'Beaches',
    'Mountains',
    'Lakes',
    'Rivers',
    'Waterfalls',
    'Countryside',
    'Ocean Views',
    'Rainforests',
    'Forests',
    'Natural Reserves',
    'Gardens',
    'Farms',
    'Coral Reefs',
    'Deserts',
    'Savannas',
    'Wetlands',
    'Glaciers',
    'Hot Springs',
    'Volcanoes',
    'Caves',
    'Fishing',
    'Surf',
    'Aquariums'
  ],
  Other: [
    'Landmarks',
    'Theme Parks',
    'Cultural Sites',
    'Historic Sites',
    'Hidden Gems',
    'Local Favorites',
    'Iconic Views',
    'Family-Friendly',
    'Zoos',
    'Ski Resort',
    'Aquariums',
    'Botanical Gardens',
    'Animal Sanctuaries',
    'Ports',
    'Art Galleries',
    'Museums',
    'Fairs',
    'Observatories',
    'Lighthouses',
    'Monuments',
    'Castles',
    'Bridges',
    'Fountains',
    'Christmas',
    'Astronomy',
    'Gaming',
    'Cruise Ships',
    'Religion',
    'Church',
    'Square',
    'Artificial Intelligence',
    'Business'
  ]
};


const countries = [
  // Sovereign States
  'Afghanistan', 'Albania', 'Algeria', 'Andorra', 'Angola', 'Argentina', 'Armenia', 'Australia', 'Austria', 'Azerbaijan', 'Bahamas', 'Bahrain', 'Bangladesh', 'Barbados', 'Belarus', 'Belgium', 'Belize', 'Benin',
  'Bhutan', 'Bolivia', 'Bosnia and Herzegovina', 'Botswana', 'Brazil', 'Brunei', 'Bulgaria', 'Burkina Faso', 'Burundi', 'Cambodia', 'Cameroon', 'Canada', 'Cape Verde', 'Central African Republic', 'Chad', 'Chile',
  'China', 'Colombia', 'Comoros', 'Costa Rica', 'Croatia', 'Cuba', 'Cyprus', 'Czech Republic', 'Denmark', 'Djibouti', 'Dominica', 'Dominican Republic', 'East Timor', 'Ecuador', 'Egypt', 'El Salvador', 'Equatorial Guinea',
  'Eritrea', 'Estonia', 'Eswatini', 'Ethiopia', 'Fiji', 'Finland', 'France', 'Gabon', 'Gambia', 'Georgia', 'Germany', 'Ghana', 'Greece', 'Greenland', 'Grenada', 'Guatemala', 'Guinea', 'Guinea-Bissau', 'Guyana',
  'Haiti', 'Honduras', 'Hungary', 'Iceland', 'India', 'Indonesia', 'Iran', 'Iraq', 'Ireland', 'Israel', 'Italy', 'Jamaica', 'Japan', 'Jordan', 'Kazakhstan', 'Kenya', 'Kiribati', 'Kosovo', 'Kuwait', 'Kyrgyzstan',
  'Laos', 'Latvia', 'Lebanon', 'Lesotho', 'Liberia', 'Libya', 'Liechtenstein', 'Lithuania', 'Luxembourg', 'Madagascar', 'Malawi', 'Malaysia', 'Maldives', 'Mali', 'Malta', 'Marshall Islands', 'Mauritania', 'Mauritius',
  'Mexico', 'Micronesia', 'Moldova', 'Monaco', 'Mongolia', 'Montenegro', 'Morocco', 'Mozambique', 'Myanmar', 'Namibia', 'Nauru', 'Nepal', 'Netherlands', 'New Zealand', 'Nicaragua', 'Niger', 'Nigeria', 'North Korea',
  'North Macedonia', 'Norway', 'Oman', 'Pakistan', 'Palau', 'Panama', 'Papua New Guinea', 'Paraguay', 'Peru', 'Philippines', 'Poland', 'Portugal', 'Qatar', 'Romania', 'Russia', 'Rwanda', 'Saint Kitts and Nevis',
  'Saint Lucia', 'Saint Vincent and the Grenadines', 'Samoa', 'San Marino', 'Sao Tome and Principe', 'Saudi Arabia', 'Senegal', 'Serbia', 'Seychelles', 'Sierra Leone', 'Singapore', 'Slovakia', 'Slovenia',
  'Solomon Islands', 'Somalia', 'South Africa', 'South Korea', 'South Sudan', 'Spain', 'Sri Lanka', 'Sudan', 'Suriname', 'Sweden', 'Switzerland', 'Syria', 'Taiwan', 'Tajikistan', 'Tanzania', 'Thailand', 'Togo',
  'Tonga', 'Trinidad and Tobago', 'Tunisia', 'Turkey', 'Turkmenistan', 'Tuvalu', 'Uganda', 'Ukraine', 'United Arab Emirates', 'United Kingdom', 'United States', 'Uruguay', 'Uzbekistan', 'Vanuatu', 'Vatican City',
  'Venezuela', 'Vietnam', 'Yemen', 'Zambia', 'Zimbabwe',

  // Dependent Territories, Autonomous Regions, and Special Entities
  'Greenland', 'Hong Kong', 'Macau', 'Puerto Rico', 'Guam', 'Bermuda', 'Faroe Islands', 'French Polynesia', 'New Caledonia', 'Falkland Islands', 'Saint Pierre and Miquelon', 'Gibraltar', 'Montserrat',
  'Curaçao', 'Aruba', 'Sint Maarten', 'Turks and Caicos Islands', 'British Virgin Islands', 'U.S. Virgin Islands', 'American Samoa', 'Northern Mariana Islands', 'Anguilla', 'Cayman Islands', 'Saint Helena',
  'Tokelau', 'Niue', 'Wallis and Futuna', 'Christmas Island', 'Cocos (Keeling) Islands', 'Cook Islands', 'Pitcairn Islands', 'Bouvet Island', 'South Georgia and the South Sandwich Islands',

  // Disputed Territories
  'Western Sahara', 'Palestine', 'Kosovo', 'Abkhazia', 'South Ossetia', 'Nagorno-Karabakh', 'Transnistria',

  // Regions often listed separately in specific contexts
  'Scotland', 'Wales', 'Northern Ireland', 'England', 'Bonaire', 'Svalbard', 'Åland Islands'
];

const geocodeTypes = [
  'place',         // General place name
  'poi',           // Points of interest (e.g., landmarks, attractions)
  'neighborhood',  // Neighborhoods
  'locality',      // Local city or town name
  'region',        // Larger geographic regions (e.g., states, provinces)
  'postcode',      // Postal codes
  'district',      // Administrative districts
  'country',       // Country name
  'address',       // Specific address or street name
  'landmark',      // Specific landmarks (often overlaps with poi)
  'county',        // County or equivalent
  'ocean',         // Ocean names
  'continent'      // Continent names
];


const regions = [
  'North America', 'South America', 'Europe', 'Asia', 'Africa', 'Oceania', 'Middle East', 'Caribbean', 'Central America', 'Southeast Asia', 'Eastern Europe', 'Central Asia', 'Southern Africa', 'Northern Africa'
];


const mapCountryToContinent = (countryCode) => {
  const countryToContinent = {
    // Africa
    DZ: 'Africa', AO: 'Africa', BJ: 'Africa', BW: 'Africa', BF: 'Africa', BI: 'Africa', CM: 'Africa', CV: 'Africa',
    CF: 'Africa', TD: 'Africa', KM: 'Africa', CD: 'Africa', CG: 'Africa', CI: 'Africa', DJ: 'Africa', EG: 'Africa',
    GQ: 'Africa', ER: 'Africa', SZ: 'Africa', ET: 'Africa', GA: 'Africa', GM: 'Africa', GH: 'Africa', GN: 'Africa',
    GW: 'Africa', KE: 'Africa', LS: 'Africa', LR: 'Africa', LY: 'Africa', MG: 'Africa', MW: 'Africa', ML: 'Africa',
    MR: 'Africa', MU: 'Africa', MA: 'Africa', MZ: 'Africa', NA: 'Africa', NE: 'Africa', NG: 'Africa', RW: 'Africa',
    ST: 'Africa', SN: 'Africa', SC: 'Africa', SL: 'Africa', SO: 'Africa', ZA: 'Africa', SS: 'Africa', SD: 'Africa',
    TZ: 'Africa', TG: 'Africa', TN: 'Africa', UG: 'Africa', ZM: 'Africa', ZW: 'Africa',

    // Asia
    AF: 'Asia', AM: 'Asia', AZ: 'Asia', BH: 'Asia', BD: 'Asia', BT: 'Asia', BN: 'Asia', KH: 'Asia', CN: 'Asia',
    CY: 'Asia', GE: 'Asia', IN: 'Asia', ID: 'Asia', IR: 'Asia', IQ: 'Asia', IL: 'Asia', JP: 'Asia', JO: 'Asia',
    KZ: 'Asia', KW: 'Asia', KG: 'Asia', LA: 'Asia', LB: 'Asia', MY: 'Asia', MV: 'Asia', MN: 'Asia', MM: 'Asia',
    NP: 'Asia', OM: 'Asia', PK: 'Asia', PH: 'Asia', QA: 'Asia', SA: 'Asia', SG: 'Asia', KR: 'Asia', LK: 'Asia',
    SY: 'Asia', TW: 'Asia', TJ: 'Asia', TH: 'Asia', TR: 'Asia', TM: 'Asia', AE: 'Asia', UZ: 'Asia', VN: 'Asia',
    YE: 'Asia',

    // Europe
    AL: 'Europe', AD: 'Europe', AT: 'Europe', BY: 'Europe', BE: 'Europe', BA: 'Europe', BG: 'Europe', HR: 'Europe',
    CY: 'Europe', CZ: 'Europe', DK: 'Europe', EE: 'Europe', FI: 'Europe', FR: 'Europe', GE: 'Europe', DE: 'Europe',
    GR: 'Europe', HU: 'Europe', IS: 'Europe', IE: 'Europe', IT: 'Europe', LV: 'Europe', LI: 'Europe', LT: 'Europe',
    LU: 'Europe', MT: 'Europe', MC: 'Europe', ME: 'Europe', NL: 'Europe', MK: 'Europe', NO: 'Europe', PL: 'Europe',
    PT: 'Europe', RO: 'Europe', RU: 'Europe', SM: 'Europe', RS: 'Europe', SK: 'Europe', SI: 'Europe', ES: 'Europe',
    SE: 'Europe', CH: 'Europe', UA: 'Europe', GB: 'Europe', VA: 'Europe',

    // North America
    AG: 'North America', BS: 'North America', BB: 'North America', BZ: 'North America', CA: 'North America',
    CR: 'North America', CU: 'North America', DM: 'North America', DO: 'North America', SV: 'North America',
    GD: 'North America', GT: 'North America', HT: 'North America', HN: 'North America', JM: 'North America',
    MX: 'North America', NI: 'North America', PA: 'North America', KN: 'North America', LC: 'North America',
    VC: 'North America', TT: 'North America', US: 'North America',

    // Oceania
    AS: 'Oceania', AU: 'Oceania', FJ: 'Oceania', KI: 'Oceania', MH: 'Oceania', FM: 'Oceania', NR: 'Oceania',
    NZ: 'Oceania', PW: 'Oceania', PG: 'Oceania', WS: 'Oceania', SB: 'Oceania', TO: 'Oceania', TV: 'Oceania',
    VU: 'Oceania',

    // South America
    AR: 'South America', BO: 'South America', BR: 'South America', CL: 'South America', CO: 'South America',
    EC: 'South America', GY: 'South America', PY: 'South America', PE: 'South America', SR: 'South America',
    UY: 'South America', VE: 'South America',
  };

  return countryToContinent[countryCode] || null;
};




const StreamCreator = () => {
  const { isAdminAuthenticated } = useContext(AdminContext);
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [videoId, setVideoId] = useState('');
  const [tag1, setTag1] = useState('');
  const [tag2, setTag2] = useState('');
  const [tag3, setTag3] = useState('');
  const [type, setType] = useState('place');
  const [wikidata, setWikidata] = useState('');
  const [qid, setQid] = useState('');
  const [website, setWebsite] = useState('');
  const [longitude, setLongitude] = useState('');
  const [latitude, setLatitude] = useState('');
  const [geocode, setGeocode] = useState('place');
  const [countryCode, setCountryCode] = useState(''); // New state for country code
  const [cameraType, setCameraType] = useState('live-cameras');
  const [isJsonFieldVisible, setJsonFieldVisible] = useState(false);
  const [jsonInput, setJsonInput] = useState('');
  const location = useLocation();
  const streamJson = location.state?.streamJson || null;
  const [showPopup, setShowPopup] = useState(false);
  const [existingFeature, setExistingFeature] = useState(null);
  const [wikidataResults, setWikidataResults] = useState([]); // Store Wikidata search results
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal state
  const [isSearching, setIsSearching] = useState(false); // Loading state for Wikidata search

  const [viewport, setViewport] = useState({
    longitude: -0.118092,
    latitude: 51.509865,
    zoom: 5,
  });


  useEffect(() => {
    if (streamJson) {
      const { properties, geometry } = streamJson;
      setName(properties.name || '');
      setVideoId(properties.videoId || '');
      setTag1(properties.tag1 || '');
      setTag2(properties.tag2 || '');
      setTag3(properties.tag3 || '');
      setType(properties.type || 'place');
      setWikidata(properties.wikidata || '');
      setQid(properties.qid || '');
      setWebsite(properties.website || '');
      setGeocode(properties.geocode || 'place');
      setLongitude(geometry?.coordinates?.[0] || '');
      setLatitude(geometry?.coordinates?.[1] || '');
    }
  }, [streamJson]);

  const [fallbackEnabled, setFallbackEnabled] = useState('no'); // State for fallback toggle


  useEffect(() => {
    if (!isAdminAuthenticated) {
      navigate('/admin/login');
    }
  }, [isAdminAuthenticated, navigate]);


  const toggleFallback = () => {
    setFallbackEnabled((prev) => (prev === 'yes' ? 'no' : 'yes'));
  };



  // Fetch QID, Wikidata, and Country Code based on coordinates and selected geocode type
  const fetchQidAndWikidata = async (lon, lat) => {
    const apiUrl = `https://api.mapbox.com/geocoding/v5/mapbox.places/${lon},${lat}.json?language=en&access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}`;
    try {
      const response = await axios.get(apiUrl);
      const features = response.data.features;

      // Find preferred feature
      const preferredFeature = features.find((feature) => feature.place_type.includes(geocode));

      if (preferredFeature) {
        setQid(preferredFeature.properties.wikidata || '');
        setWikidata(preferredFeature.place_name || '');
      } else {
        setQid('');
        setWikidata('');
      }

      // Extract country information
      const countryFeature = features.find((f) => f.place_type.includes('country'));
      if (countryFeature) {
        setCountryCode(countryFeature.properties.short_code?.toUpperCase() || '');
        setTag2(countryFeature.text); // Set country as Tag 2
      } else {
        setCountryCode('');
        setTag2('');
      }

      // Determine continent from country or region
      const context = preferredFeature?.context || [];
      let continent = '';

      // Mapbox doesn't directly provide a "continent," so we infer it based on country or region
      const countryCode = countryFeature?.properties?.short_code?.toUpperCase();
      if (countryCode) {
        continent = mapCountryToContinent(countryCode);
      }

      if (continent) {
        setTag3(continent);
      } else {
        console.warn('No continent information found for country code:', countryCode);
        setTag3('');
      }
    } catch (error) {
      console.error('Error fetching QID, Wikidata, and location tags:', error);
      setQid('');
      setWikidata('');
      setCountryCode('');
      setTag2('');
      setTag3('');
    }
  };

  // Update QID, Wikidata, and Country Code whenever coordinates or geocode type are changed
  useEffect(() => {
    if (longitude && latitude) {
      fetchQidAndWikidata(longitude, latitude);
    }
  }, [longitude, latitude, geocode]);


  // Fetch Wikidata results based on name
  const fetchWikidataResults = async () => {
    if (!name.trim()) return;
    setIsSearching(true); // Show loader
    try {
      const response = await axios.get(
        'https://www.wikidata.org/w/api.php',
        {
          params: {
            action: 'wbsearchentities',
            format: 'json',
            language: 'en',
            search: name,
            origin: '*', // CORS support
          },
        }
      );
      setWikidataResults(response.data.search || []);
    } catch (error) {
      console.error('Error fetching Wikidata results:', error);
      setWikidataResults([]);
    } finally {
      setIsSearching(false); // Hide loader
    }
  };

  const handleSelectQid = (selectedQid) => {
    setQid(selectedQid); // Update QID field
    setIsModalOpen(false); // Close modal
  };

  const checkForConflicts = async () => {
    try {
      const response = await axios.get(
        `https://server.earf.tv/${cameraType}/check`,
        {
          headers: {
            'x-api-key': process.env.REACT_APP_EARF_API_KEY,
          },
          params: {
            name,
            videoId,
          },
        }
      );

      console.log('Check response:', response.data); // Log the response

      if (response.data.conflict) {
        setExistingFeature(response.data.feature); // Store the conflicting feature
        console.log('Existing feature found:', response.data.feature);
        setShowPopup(true); // Display conflict popup
      } else {
        submitFeature(); // No conflict, directly submit the feature
      }
    } catch (error) {
      console.error('Error checking for conflicts:', error);
      alert('Failed to check for conflicts. Please try again.');
    }
  };


  const handleJsonInput = () => {
    try {
      const parsedJson = JSON.parse(jsonInput);

      if (!parsedJson.properties || typeof parsedJson.properties !== 'object') {
        throw new Error('Missing or invalid "properties" in JSON.');
      }

      const { properties, geometry } = parsedJson;

      setName(properties.name || '');
      setVideoId(properties.videoId || '');
      setTag1(properties.tag1 || '');
      setTag2(properties.tag2 || '');
      setTag3(properties.tag3 || '');
      setType(properties.type || 'place');
      setWikidata(properties.wikidata || '');
      setQid(properties.qid || '');
      setWebsite(properties.website || '');
      setGeocode(properties.geocode || 'place');

      if (geometry && geometry.coordinates && Array.isArray(geometry.coordinates)) {
        setLongitude(geometry.coordinates[0] || '');
        setLatitude(geometry.coordinates[1] || '');
      } else {
        setLongitude('');
        setLatitude('');
      }

      alert('Form successfully populated from JSON!');
      setJsonFieldVisible(false);
    } catch (error) {
      console.error('Error parsing JSON:', error);
      alert('Invalid JSON format. Please check your input.');
    }
  };

  // Extract YouTube Video ID from URL or input
  const handleVideoIdInput = (e) => {
    const input = e.target.value.trim();
    const videoIdMatch = input.match(/(?:v=|youtu\.be\/|embed\/|\/v\/|watch\?v=|watch\?.+&v=)([a-zA-Z0-9_-]{11})/);
    if (videoIdMatch) {
      setVideoId(videoIdMatch[1]);
    } else {
      setVideoId(input); // Assume raw video ID if no URL match
    }
  };

  // Extract Latitude and Longitude from Google Maps URL
  const handleLatLonInput = (input, type) => {
    const latLonMatch = input.match(/ll=([-.\d]+),([-.\d]+)/);
    if (latLonMatch) {
      const lat = parseFloat(latLonMatch[1]);
      const lon = parseFloat(latLonMatch[2]);
      if (type === 'latitude') setLatitude(lat);
      if (type === 'longitude') setLongitude(lon);
    } else {
      if (type === 'latitude') setLatitude(input);
      if (type === 'longitude') setLongitude(input);
    }
  };

  const submitFeature = async () => {
    try {
      // Fetch video details from YouTube API
      const youtubeApiUrl = `https://www.googleapis.com/youtube/v3/videos?id=${videoId}&part=snippet&key=${process.env.REACT_APP_YOUTUBE_API_KEY}`;
      const youtubeResponse = await fetch(youtubeApiUrl);
      const youtubeData = await youtubeResponse.json();

      if (!youtubeData.items || youtubeData.items.length === 0) {
        throw new Error('Invalid YouTube Video ID or video not found.');
      }

      const videoDetails = youtubeData.items[0];
      const channelId = videoDetails.snippet.channelId;
      const channelName = videoDetails.snippet.channelTitle;
      const thumbnail = `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;

      // Fetch channel details to get channel thumbnail
      const channelApiUrl = `https://www.googleapis.com/youtube/v3/channels?id=${channelId}&part=snippet&key=${process.env.REACT_APP_YOUTUBE_API_KEY}`;
      const channelResponse = await fetch(channelApiUrl);
      const channelData = await channelResponse.json();

      if (!channelData.items || channelData.items.length === 0) {
        throw new Error('Channel details not found.');
      }

      const channelThumbnail = channelData.items[0].snippet.thumbnails.default.url;

      // Submit the feature to your backend
      const response = await fetch('https://server.earf.tv/add-feature', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': process.env.REACT_APP_EARF_API_KEY,
        },
        body: JSON.stringify({
          name,
          videoId,
          channelId,
          channelName,
          channelThumbnail,
          thumbnail,
          status: 'online',
          latitude: parseFloat(latitude) || null,
          longitude: parseFloat(longitude) || null,
          qid: qid || null,
          wikidata: wikidata || null, // Include wikidata
          website: website || null,
          tag1,
          tag2,
          tag3,
          cameraType, // 'live-cameras' or 'browse-cameras'
          useChannelForLiveCheck: fallbackEnabled,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        alert(`Feature added successfully with ID: ${data.id}`);

        // Option 1: Reset form
        setName('');
        setVideoId('');
        setTag1('');
        setTag2('');
        setTag3('');
        setType('place');
        setWikidata('');
        setQid('');
        setWebsite('');
        setLongitude('');
        setLatitude('');
        setGeocode('place');
        setCountryCode('');

        // Option 2: Reload the page
        // window.location.reload();
      } else {
        alert(data.message || 'Failed to add feature.');
      }
    } catch (error) {
      console.error('Error adding feature:', error);
      alert(error.message || 'Failed to add feature. Please try again.');
    }
  };

  const adminMapClick = (event) => {
    const { lngLat } = event;
    setLongitude(lngLat.lng.toFixed(6));
    setLatitude(lngLat.lat.toFixed(6));
  };

  // Update viewport when coordinates change
  useEffect(() => {
    if (longitude && latitude) {
      setViewport((prev) => ({
        ...prev,
        longitude: parseFloat(longitude),
        latitude: parseFloat(latitude),
        zoom: 18, // Set zoom level to 15
        transitionDuration: 500, // Smooth transition
      }));
    }
  }, [longitude, latitude]);

  return (
    <div className={styles['feature-creator-container']}>
      <Helmet prioritizeSeoTags>
        <title>Feature Creator | Earf Admin</title>
        <meta name="description" content="Login to the Earf.TV admin panel to manage streams and settings." />
        <meta property="og:image" content="https://earf.tv/metaimage.png" />
        <meta name="twitter:image" content="https://earf.tv/metaimage.png" />
      </Helmet>

      <div className={styles['header-container']}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img src="/christmasearfy.png" alt="Earf.TV Logo" className={styles['logo']} />
          <h1 className={styles['page-title']}>Earf Feature Creator</h1>
        </div>
        <button className="back-button" onClick={() => navigate(-1)}>
          ← Back
        </button>
      </div>

      {/* Toggle JSON Input Field */}
      <div className={styles['form-section']}>
        <button onClick={() => setJsonFieldVisible(!isJsonFieldVisible)} className={styles['toggle-button-dark']}>
          {isJsonFieldVisible ? 'Hide Import' : 'Import Location'}
        </button>
        {isJsonFieldVisible && (
          <div className={styles['json-input-container']}>
            <textarea
              className={styles['json-textarea-dark']}
              value={jsonInput}
              onChange={(e) => setJsonInput(e.target.value)}
              placeholder="Paste feature JSON here..."
            />
            <button onClick={handleJsonInput} className={styles['parse-button-dark']}>
              Parse JSON
            </button>
          </div>
        )}
      </div>

      {/* General Info Section */}
      <div className={styles['form-container']}>
        <div className={styles['form-section']}>
          <h2>General Info</h2>

          <div className={styles['form-group']}>
            <label>Feature Name *</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter feature name"
              required
            />
          </div>

          <div className={styles['form-group']}>
            <label>YouTube Video ID or URL</label>
            <input
              type="text"
              value={videoId}
              onChange={handleVideoIdInput}
              placeholder="Enter YouTube video ID or URL"
            />
          </div>

          <div className={styles['form-group']}>
            <label>Feature Type *</label>
            <select value={type} onChange={(e) => setType(e.target.value)} required>
              {['place', 'airport', 'rail', 'animal', 'beach', 'news', 'walking', 'driving', 'drone'].map((type) => (
                <option key={type} value={type}>{type}</option>
              ))}
            </select>
          </div>

          <div className={styles['form-group']}>
            <label>Website</label>
            <input type="url" value={website} onChange={(e) => setWebsite(e.target.value)} placeholder="Enter website" />
          </div>
          {/* Fallback Toggle */}

          <button
            className={fallbackEnabled === 'yes' ? styles['button-green'] : styles['button-red']}
            onClick={toggleFallback}
          >
            {fallbackEnabled === 'yes' ? 'Disable Channel Fallback' : 'Enable Channel Fallback'}
          </button>
          
        <button
          className={styles['search-wikidata-button']}
          onClick={() => {
            fetchWikidataResults();
            setIsModalOpen(true);
          }}
        >
          Search Wikidata
        </button>
        </div>

        {/* Location Info Section */}
        <div className={styles['form-section']}>
          <h2>Location Info</h2>
          <div className={styles['form-group']}>
            <label>Longitude</label>
            <input
              type="text"
              value={longitude}
              onChange={(e) => handleLatLonInput(e.target.value, 'longitude')}
              placeholder="Enter longitude or Google Maps URL"
            />
          </div>

          <div className={styles['form-group']}>
            <label>Latitude</label>
            <input
              type="text"
              value={latitude}
              onChange={(e) => handleLatLonInput(e.target.value, 'latitude')}
              placeholder="Enter latitude or Google Maps URL"
            />
          </div>
          <div className={styles['form-group']}>
            <label>QID</label>
            <input
              type="text"
              value={qid}
              onChange={(e) => setQid(e.target.value)}
              placeholder="Enter QID manually or use the search"
            />
          </div>


          <div className={styles['form-group']}>
            <label>Geocode Type *</label>
            <select value={geocode} onChange={(e) => setGeocode(e.target.value)} required>
              {geocodeTypes.map((code) => (
                <option key={code} value={code}>{code}</option>
              ))}
            </select>
          </div>

          <div className={styles['form-group']}>
            <label>Wikidata</label>
            <input type="text" value={wikidata} onChange={(e) => setWikidata(e.target.value)} placeholder="Enter wikidata" />
          </div>

          <div className={styles['form-group']}>
            <label>Country Code</label>
            <input type="text" value={countryCode} onChange={(e) => setCountryCode(e.target.value)} placeholder="Auto-populated country code" readOnly />
          </div>
        </div>

        {/* Tags Section */}
        <div className={styles['form-section']}>
          <h2>Tags</h2>
          <div className={styles['form-group']}>
            <label>Tag 1 *</label>
            <select value={tag1} onChange={(e) => setTag1(e.target.value)} required>
              <option value="">Select Tag 1</option>
              {Object.keys(categories).map((category) => (
                <optgroup label={category} key={category}>
                  {categories[category].map((tag) => (
                    <option key={tag} value={tag}>{tag}</option>
                  ))}
                </optgroup>
              ))}
            </select>
          </div>

          <div className={styles['form-group']}>
            <label>Tag 2 (Country) *</label>
            <select value={tag2} onChange={(e) => setTag2(e.target.value)} required>
              <option value="">Select Country</option>
              {countries.map((country) => (
                <option key={country} value={country}>{country}</option>
              ))}
            </select>
          </div>

          <div className={styles['form-group']}>
            <label>Tag 3 (Region) *</label>
            <select value={tag3} onChange={(e) => setTag3(e.target.value)} required>
              <option value="">Select Region</option>
              {regions.map((region) => (
                <option key={region} value={region}>{region}</option>
              ))}
            </select>
          </div>

          <button className={styles['submit-button']} onClick={checkForConflicts}>
            Submit Feature
          </button>


        </div>


        {/* Camera Type and Map Section */}
        <div className={styles['form-section']}>
          <div className={styles['form-group']}>
            <label>Select Camera Type *</label>
            <select value={cameraType} onChange={(e) => setCameraType(e.target.value)} required>
              <option value="live-cameras">Live Cameras</option>
              <option value="browse-cameras">Browse Cameras</option>
            </select>
          </div>

          <div style={{ height: '300px', marginBottom: '20px' }}>
            <Map
              {...viewport}
              width="100%"
              height="100%"
              mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
              mapStyle="mapbox://styles/mapbox/standard"
              onMove={(evt) => setViewport(evt.viewState)}
              onClick={adminMapClick}
              projection="globe" // Set projection to globe for a spherical view
            >
              {longitude && latitude && (
                <Marker longitude={parseFloat(longitude)} latitude={parseFloat(latitude)} />
              )}
            </Map>
          </div>


        </div>
        {showPopup && (
          <div className={styles['popup']}>
            <p>
              A feature with the same video ID already exists. It must be deleted before proceeding.
            </p>
            <button
              className={styles['popup-button']}
              onClick={() => window.open('/admin/feature-editor', '_blank')}
            >
              Go to Feature Editor
            </button>
            <button className={styles['popup-cancel']} onClick={() => setShowPopup(false)}>
              Cancel
            </button>
          </div>
        )}

      {/* Modal for Wikidata Search Results */}
      {isModalOpen && (
        <div className={styles['modal-overlay']}>
          <div className={styles['modal-content']}>
            <h3>Wikidata Search Results</h3>
            {isSearching && <p>Loading results...</p>}
            {!isSearching && wikidataResults.length === 0 && (
              <p>No results found for "{name}".</p>
            )}
            {!isSearching && wikidataResults.length > 0 && (
              <ul className={styles['wikidata-results']}>
                {wikidataResults.map((result) => (
                  <li key={result.id} className={styles['result-item']}>
                    <div>
                      <strong>{result.label}</strong> ({result.id})
                      <p>{result.description}</p>
                    </div>
                    <div className={styles['result-actions']}>
                      <button
                        className={styles['use-button']}
                        onClick={() => handleSelectQid(result.id)}
                      >
                       USE
                      </button>
                      <button
                        className={styles['view-button']}
                        onClick={() => window.open(`https://www.wikidata.org/wiki/${result.id}`, '_blank')}
                      >
                        VIEW
                      </button>
                    </div>
                  </li>
                ))}
              </ul>
            )}
            <button
              className={styles['close-modal-button']}
              onClick={() => setIsModalOpen(false)}
            >
              Close
            </button>
          </div>
        </div>
      )}
      </div>
    </div>
  );
};

export default StreamCreator;