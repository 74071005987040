import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Loader from '../Loader/Loader';
import styles from './SimilarVideosSidebar.module.css';

const SimilarVideosSidebar = ({ wikidata, tags, currentVideoId }) => {
  const [similarVideos, setSimilarVideos] = useState([]);
  const [headerText, setHeaderText] = useState('');
  const [locationName, setLocationName] = useState(''); // For dynamic location name
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      clearTimeout(window.resizeTimeout);
      window.resizeTimeout = setTimeout(() => {
        setIsMobile(window.innerWidth <= 768);
      }, 150);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      clearTimeout(window.resizeTimeout);
    };
  }, []);

  useEffect(() => {
    const fetchSimilarVideos = async () => {
      setIsLoading(true);
      try {
        const liveResponse = await fetch('https://server.earf.tv/live-cameras', {
          method: "POST",
          headers: {
            'x-api-key': process.env.REACT_APP_EARF_API_KEY,
            'Content-Type': 'application/json',
            'X-Request-Source': 'Similar-Videos-Live',
          },
        });
        const liveData = await liveResponse.json();

        const browseResponse = await fetch('https://server.earf.tv/browse-cameras', {
          method: "POST",
          headers: {
            'x-api-key': process.env.REACT_APP_EARF_API_KEY,
            'Content-Type': 'application/json',
            'X-Request-Source': 'Similar-Videos-Browse',

          },
        });
        const browseData = await browseResponse.json();

        const onlineLiveVideos = (liveData.features || []).filter(
          (video) => video.properties.status === 'online'
        );
        const onlineBrowseVideos = (browseData.features || []).filter(
          (video) => video.properties.status === 'online'
        );

        const allVideos = [...onlineLiveVideos, ...onlineBrowseVideos];

        // Split wikidata into parts for progressive search
        const wikidataParts = wikidata ? wikidata.split(',').map((part) => part.trim()) : [];
        let relatedVideos = [];

        for (let i = 0; i < wikidataParts.length; i++) {
          relatedVideos = allVideos.filter(
            (video) =>
              video.properties.wikidata &&
              video.properties.wikidata.includes(wikidataParts[i]) &&
              video.properties.videoId !== currentVideoId
          );
        
          if (relatedVideos.length > 0) {
            // Extract country from the last part of wikidata
            const country = wikidataParts[wikidataParts.length - 1];
        
            // Check if the current part is already the same as the country
            const location = wikidataParts[i] === country ? country : `${wikidataParts[i]}, ${country}`;
        
            setLocationName(location); // Set location name without duplication
            setHeaderText(`Locations Nearby`);
            break;
          }
        }
        
        // Fallback to tags if no location matches found
        if (relatedVideos.length === 0 && tags.length > 0) {
          for (let i = 0; i < tags.length; i++) {
            relatedVideos = allVideos.filter((video) => {
              const tag = `tag${i + 1}`;
              return (
                video.properties[tag] &&
                video.properties[tag] === tags[i] &&
                video.properties.videoId !== currentVideoId
              );
            });
            if (relatedVideos.length > 0) {
              setHeaderText(
                <>
                  Similar <span style={{ color: 'var(--earf-blue)' }}>{tags[i]}</span>
                </>
              );
              break;
            }
          }
        }

        setSimilarVideos(relatedVideos);
      } catch (error) {
        console.error('Error fetching similar videos:', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (wikidata || tags.length > 0) {
      fetchSimilarVideos();
    }
  }, [wikidata, tags, currentVideoId]);

  const handleVideoClick = (videoId, tag2) => {
    navigate(`/stream/${videoId}`, { state: { fromSidebar: true, tag: tag2 } });
  };

  const displayedVideos = isMobile ? similarVideos.slice(0, 2) : similarVideos;

  return (
    <div className={`${styles['similar-videos-sidebar']} ${isLoading ? '' : styles['fade-in']}`}>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {/* Render header after loading */}
          <div className={styles['similar-video-header-container']}>
            <h3>{headerText}</h3>
            {locationName && (
              <h4>
                <span style={{ color: 'var(--earf-blue)' }}>{locationName}</span>
              </h4>
            )}
          </div>
  
          {/* Render content */}
          <div className={styles['sidebar-scrollable-content']}>
            {displayedVideos.length > 0 ? (
              displayedVideos.map((video) => (
                <div
                  className={styles['similar-video-item']}
                  onClick={() => handleVideoClick(video.properties.videoId, video.properties.tag2)}
                  key={video.properties.videoId}
                >
                  <img
                    src={`https://img.youtube.com/vi/${video.properties.videoId}/mqdefault.jpg`}
                    alt={video.properties.name}
                    className={styles['similar-video-thumbnail']}
                  />
                  <div className={styles['similar-video-content']}>
                    <p className={styles['name']}>{video.properties.name}</p>
                    <p className={styles['wikidata']}>
                      {video.properties.wikidata || 'No Wikidata available'}
                    </p>
                    <div className={styles['channel-info']}>
                      <img
                        src={video.properties.channelThumbnail}
                        alt={`${video.properties.channelName} thumbnail`}
                        className={styles['channel-thumbnail']}
                      />
                      <span className={styles['channel-name']}>{video.properties.channelName}</span>
                    </div>
                    <div className={styles['sidebar-tags']}>
                      {[video.properties.tag1, video.properties.tag2, video.properties.tag3]
                        .filter(Boolean)
                        .map((tag, index) => (
                          <span key={index} className={styles['sidebar-tag']}>
                            {tag}
                          </span>
                        ))}
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p className={styles['name']}>No related videos found.</p>
            )}
          </div>
        </>
      )}
    </div>
  );
  
};

export default SimilarVideosSidebar;
