import React, { useRef, useEffect } from 'react';
import emailjs from '@emailjs/browser';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for the back button functionality
import './Contact.css'; // Optional CSS for styling
import { Helmet } from 'react-helmet-async';

const Contact = () => {
    const form = useRef();
    const navigate = useNavigate(); // Hook for navigation

    // Initialize EmailJS with the public key on component load
    useEffect(() => {
        emailjs.init('r6SlRwhc0oMyvh312'); // Replace with your actual EmailJS public key
    }, []);

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm(
            'service_31xlehj',    // Replace with your Service ID
            'template_7h2wgfk',   // Replace with your Template ID
            form.current
        ).then(
            (result) => {
                alert('Message sent successfully!');
            },
            (error) => {
                alert('Failed to send the message, please try again.');
            }
        );
        e.target.reset(); // Reset form after submission
    };

    return (
        <div className='content-wrapper'>
        <div className="contact-container">
      <Helmet prioritizeSeoTags>
        {/* Page Title */}
        <title>Help and Support | Earf</title>

        {/* Meta Description */}
        <meta
          name="description"
          content="Get in touch with Earf for any inquiries or support."
        />

        {/* Canonical Tag */}
        <link rel="canonical" href="https://earf.tv/help-support" />

        {/* Open Graph Meta Tags */}
        <meta property="og:title" content="Help and Support | Earf" />
        <meta
          property="og:description"
          content="Get in touch with Earf for any inquiries or support."
        />
        <meta property="og:image" content="https://earf.tv/metaimage.png" />
        <meta property="og:url" content="https://earf.tv/help-support" />
        <meta property="og:type" content="website" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="Help and Support | Earf" />
        <meta
          name="twitter:description"
          content="Get in touch with Earf for any inquiries or support."
        />
        <meta name="twitter:image" content="https://earf.tv/metaimage.png" />
        <meta name="twitter:image:alt" content="Earf Logo" />
      </Helmet>



            <div className="header-container">
                <h1 className="contact-header">Help and Support</h1>
                {/* Back button to go to the previous page */}
                <button className="back-button" onClick={() => navigate(-1)}>
                    Back
                </button>
            </div>

            <form ref={form} onSubmit={sendEmail} className="contact-form">
                <label htmlFor="name">Name</label>
                <input type="text" id="name" name="user_name" required placeholder="Your Name" />

                <label htmlFor="email">Email</label>
                <input type="email" id="email" name="user_email" required placeholder="Your Email" />

                <label htmlFor="issue">Issue</label>
                <select id="issue" name="issue" required>
                    <option value="" disabled selected>-- Select an issue --</option>
                    <option value="General Inquiry">General Inquiry</option>
                    <option value="Customer Support">Customer Support</option>
                    <option value="Technical Issue">Technical Issue</option>
                    <option value="App or Website Issue">App or Website Issue</option>
                    <option value="Slow Loading Issue">Slow Loading Issue</option>
                    <option value="Audio or Video Issue">Audio or Video Issue</option>
                    <option value="Report a Bug">Report a Bug</option>
                    <option value="Security Issue">Security Issue</option>
                    <option value="Feature Request">Feature Request</option>
                    <option value="Content Feedback">Content Feedback</option>
                    <option value="One-Time Donation">One-Time Donation</option>
                    <option value="Partnership Inquiry">Partnership Inquiry</option>
                    <option value="Charity Partnership Inquiry">Charity Partnership Inquiry</option>
                    <option value="Advertising Inquiry">Advertising Inquiry</option>
                    <option value="Legal or Copyright Issue">Legal or Copyright Issue</option>
                    <option value="Privacy Concern">Privacy Concern</option>
                    <option value="Other">Other</option>
                </select>

                <label htmlFor="message">Message</label>
                <textarea id="message" name="message" rows="5" required placeholder="Describe your issue here"></textarea>

                <button type="submit">Submit</button>
            </form>
        </div>
        </div>
    );
};

export default Contact;
