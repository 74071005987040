import React, { useContext, useState } from 'react';
import { AdminContext } from '../AdminContext'; // Import the context
import { useNavigate } from 'react-router-dom'; // For navigation
import { Helmet } from 'react-helmet-async';
import styles from './AdminLogin.module.css'; // Updated to use CSS modules

const AdminLogin = () => {
  const [inputPassword, setInputPassword] = useState('');
  const [inputUsername, setInputUsername] = useState('');
  const [errorMessage, setErrorMessage] = useState(''); // For error handling
  const { loginAdmin } = useContext(AdminContext); // Get login function from context
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior

    try {
      const response = await fetch('https://server.earf.tv/admin/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': process.env.REACT_APP_EARF_API_KEY,  // Add your API key here
        },
        body: JSON.stringify({
          username: inputUsername,
          password: inputPassword,
        }),
      });

      if (response.ok) {
        // Login successful
        loginAdmin(); // Set the authentication state in context
        navigate('/admin/dashboard'); // Navigate to admin dashboard upon successful login
      } else if (response.status === 429) {
        // Rate limit exceeded
        const data = await response.json(); // The rate-limiting message is in the response
        setErrorMessage(data.message || 'Too many login attempts from this IP, please try again later.');
      } else {
        // Handle other failed login attempts
        const data = await response.json();
        setErrorMessage(data.message || 'Login failed');
      }
    } catch (error) {
      console.error('Error logging in:', error);
      setErrorMessage('An error occurred while trying to log in. Please try again.');
    }
  };

  return (
    <div className={styles['admin-login-container']}>
      <Helmet prioritizeSeoTags>
        <title>Admin Login | Earf Admin</title>
        <meta name="description" content="Login to the Earf.TV admin panel to manage streams and settings." />
        <meta property="og:image" content="https://earf.tv/metaimage.png" />
        <meta name="twitter:image" content="https://earf.tv/metaimage.png" />
      </Helmet>

      <img src="/christmasearfy.png" alt="Earf.TV Logo" className={styles['admin-logo']} />
      <h1>
        Welcome <span className={styles['admin-word']}>Admin</span>
      </h1>
      <p>Please login to continue</p>

      {errorMessage && <p className={styles['error-message']}>{errorMessage}</p>} {/* Display error message if login fails */}

      <form onSubmit={handleLogin} className={styles['admin-login-form']}>
  <input
    type="text"
    value={inputUsername}
    onChange={(e) => setInputUsername(e.target.value)}
    placeholder="Enter username"
    className={styles['admin-input']}
  />
  <input
    type="password"
    value={inputPassword}
    onChange={(e) => setInputPassword(e.target.value)}
    placeholder="Enter password"
    className={styles['admin-input']}
  />
  
  <div className={styles['admin-buttons']}>
  <button
      type="button"
      className='back-button'
      onClick={() => navigate('/')}
    >
      Back
    </button>
    {/* Warning message between Back and Login buttons */}
    <div className={styles['warning-message']}>
      <p>Your IP address will be logged.</p>
    </div>

    <button type="submit" className={styles['login-button']}>
      Login
    </button>
  </div>
</form>

    </div>
  );
};

export default AdminLogin;
