// AlertSystem.js
import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './AlertSystem.css'; // Import the CSS file for styling

const AlertSystem = () => {
  // Function to trigger a system outage alert
  const triggerOutageAlert = () => {
    toast.error("Some services are unavailable.", {
      position: "top-center",
      autoClose: 8000,  // Auto close after 8 seconds
      closeOnClick: true,
      hideProgressBar: false,
      pauseOnHover: true,
      draggable: true,
      closeButton: false
    });
  };


  // Example: Trigger an alert on component mount (can be replaced with logic)
  useEffect(() => {
  }, []);


};

export default AlertSystem;
