import React from 'react';
import { useNavigate } from 'react-router-dom';
import './TermsOfService.css'; // CSS file for styling
import { Helmet } from 'react-helmet-async';

const TermsOfService = () => {
  const navigate = useNavigate();

  return (
    <div className="terms-container">
<Helmet prioritizeSeoTags>
  {/* Page Title */}
  <title>Terms of Service | Earf</title>

  {/* Meta Description */}
  <meta
    name="description"
    content="Read the Terms of Service for Earf, the live-streaming platform dedicated to Earth's beauty and real-time information. Understand the rules, policies, user rights, and responsibilities that apply when using our services."
  />

  {/* Canonical Tag */}
  <link rel="canonical" href="https://earf.tv/terms-of-service" />

  {/* Open Graph Meta Tags */}
  <meta property="og:title" content="Terms of Service | Earf" />
  <meta
    property="og:description"
    content="Read the Terms of Service for Earf to understand your rights, responsibilities, and the policies governing the use of our live-streaming platform featuring Earth's beauty and real-time information."
  />
  <meta property="og:image" content="https://earf.tv/metaimage.png" />
  <meta property="og:url" content="https://earf.tv/terms-of-service" />
  <meta property="og:type" content="website" />
  <meta property="og:image:width" content="1200" />
  <meta property="og:image:height" content="630" />

  {/* Twitter Card Meta Tags */}
  <meta name="twitter:card" content="summary" />
  <meta name="twitter:title" content="Terms of Service | Earf" />
  <meta
    name="twitter:description"
    content="Explore the Terms of Service for Earf to learn about our policies, your user rights, and the responsibilities when using our platform showcasing Earth's beauty through live streams."
  />
  <meta name="twitter:image" content="https://earf.tv/metaimage.png" />
  <meta name="twitter:image:alt" content="Earf Terms of Service" />
</Helmet>

      
      {/* Header with Back Button (fixed at the top) */}
      <div className="header-container">
        <h1 className="terms-header">Terms of Service</h1>
        <button className="back-button" onClick={() => navigate(-1)}>
          Back
        </button>
      </div>

      {/* Scrollable content section */}
      <div className="terms-content">
        <p>
          <strong>Effective Date:</strong> October 2024
        </p>

        {/* Terms of Service */}
        <p>
          Earf, through its platform <strong>Earf.TV</strong>, previously known as <strong>Interactive.Earth</strong>, is committed to providing services in accordance with these Terms of Service. This document outlines your rights and obligations when using the Website.
        </p>

        <p>
          References to "we," "us," and "our" refer to <strong>Earf</strong>, <strong>Earf.TV</strong>, and <strong>Interactive.Earth</strong>, including their subdomains and related services. By using the Website, you agree to comply with these Terms.
        </p>

        <p>
          By accessing or using <strong>Earf</strong> (the "Website"), including any domains or subdomains associated with <strong>Earf.TV</strong> and <strong>Interactive.Earth</strong>, you confirm that you have read, understood, and agreed to these Terms of Service. If you do not agree, please discontinue your use of the Website immediately.
        </p>

        <h3>1. Acceptance of Privacy Policy</h3>
        <p>
          Your use of the Website is governed by our Privacy Policy. Please review the <a href="/privacy-policy" target="_blank">Privacy Policy</a> to understand our policies regarding the collection, use, and disclosure of your information. By accessing and using the Website, you consent to the collection, use, and disclosure of your information as described in the Privacy Policy.
        </p>

        <h3>2. Acceptance of DMCA & Copyright Policies</h3>
        <p>
          By accessing the Website, you agree to abide by our DMCA & Copyright policies. Please take a moment to review our <a href="/dmca" target="_blank">DMCA & Copyright</a> policies to understand the guidelines governing the information provided on this Website. Your use of the Website constitutes acknowledgment and acceptance of these policies.
        </p>

        <h3>3. Compliance with Regulations</h3>
        <ul>
          <li>
            <strong>GDPR Compliance:</strong> By using the Website, you acknowledge that we comply with the General Data Protection Regulation (GDPR) in processing your personal data, which includes respecting your rights as a data subject such as the right to access, correct, and delete your personal information.
          </li>
          <li>
            <strong>CPRA Compliance:</strong> As a user from California, you agree that we will handle your personal data in accordance with the California Privacy Rights Act (CPRA), which includes the rights to opt-out, the right to know, and the right to delete your data.
          </li>
          <li>
            <strong>LGPD Compliance:</strong> By using the Website, you acknowledge that we comply with the Brazilian General Data Protection Law (LGPD) in processing your personal data. You have rights such as access, correction, and deletion of your data.
          </li>
          <li>
            <strong>Data Transfers:</strong> If we transfer your data outside your country, we will ensure that adequate safeguards are in place to protect your data according to the applicable regulations.
          </li>
          <li>
            <strong>User Responsibilities:</strong> You agree to exercise your rights in accordance with the regulations governing your jurisdiction and to use our contact information for any privacy-related concerns.
          </li>
        </ul>

        <h3>4. User Obligations Regarding Intellectual Property</h3>
        <p>
          By using the Website, you agree not to infringe upon the intellectual property rights of <strong>Earf</strong>, including <strong>Earf.TV</strong> and <strong>Interactive.Earth</strong>, or any third parties. This includes, but is not limited to:
        </p>
        <ul>
          <li>Reproducing, distributing, or creating derivative works from any content without prior written consent from the respective owners.</li>
          <li>Using any trademarks, logos, or service marks without explicit permission.</li>
          <li>Engaging in any activity that would violate copyright, trademark, or other intellectual property laws.</li>
        </ul>
        <p>
          Violations of intellectual property rights may result in legal action, including injunctions, damages, and other remedies available under law.
        </p>

        <h3>5. Intellectual Property Rights</h3>
        <p>
          All content, including text, graphics, logos, images, and video content, on the Website is the intellectual property of <strong>Earf</strong> (including <strong>Earf.TV</strong> and <strong>Interactive.Earth</strong>) or its respective owners. You may not use, reproduce, distribute, or create derivative works without explicit written permission from the copyright owners. Any unauthorized use of the content may violate copyright, trademark, and other laws, and may result in legal action.
        </p>

        <h3>6. Use of Information</h3>
        <p>
          We collect and use information as outlined in our Privacy Policy. By using the Website, you consent to the collection and use of your information in accordance with our Privacy Policy. This includes information provided by you directly or automatically collected through cookies and other tracking technologies.
        </p>

        <h3>7. Use of YouTube API</h3>
        <p>
          Our application integrates with the YouTube API. By using our application, you agree to be bound by YouTube's Terms of Service. You can review YouTube's Terms of Service here: <a href="https://www.youtube.com/t/terms" target="_blank">YouTube Terms of Service</a>.
        </p>

        <h3>8. Limitation of Liability</h3>
        <p>
          Earf (including <strong>Earf.TV</strong> and <strong>Interactive.Earth</strong>) and its affiliates are not liable for any direct, indirect, incidental, consequential, or punitive damages arising out of your access to or use of the Website. This includes, but is not limited to, damages for loss of profits, goodwill, data, or other intangible losses. You acknowledge and agree that your use of the Website is at your own risk, and we provide the Website on an "as-is" basis without warranties of any kind.
        </p>

        <h3>9. Third-Party Links</h3>
        <p>
          The Website may contain links to third-party websites. We are not responsible for the content, privacy policies, or practices of these linked sites. Your interaction with any third-party website is at your own risk, and you should review the terms and policies of those websites before engaging with them.
        </p>
        <h3>10. Modifications</h3>
        <p>
          We reserve the right to modify, suspend, or terminate the Website or these terms at any time without prior notice. We may update these terms to reflect changes in our practices, legal requirements, or for other reasons. It is your responsibility to review these terms periodically for changes, as continued use of the Website after modifications constitutes acceptance of the updated terms.
        </p>

        <h3>11. User Responsibilities</h3>
        <p>
          You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account. You agree to notify us immediately of any unauthorized use of your account or any other security breach. You also agree to comply with all applicable laws and regulations while using the Website.
        </p>

        <h3>12. Dispute Resolution</h3>
        <p>
          PLEASE READ THIS SECTION CAREFULLY. Any disputes arising from or relating to your use of the Website shall be resolved through good-faith negotiation. If the dispute cannot be resolved informally, you agree to resolve the dispute through binding arbitration.
        </p>
        <p>
          By using the Website, you expressly waive your right to seek relief in a court of law, including the right to a jury trial and class, for any claims arising from or related to these Terms or your use of the Website. Furthermore, unless you are an Australian user or you opt out of arbitration pursuant to the instructions provided, you waive your right to participate as a plaintiff or class member in any class, collective, or representative action or proceeding.
        </p>
        <p>
          The arbitration will be conducted in accordance with the laws of Australia. You acknowledge that by agreeing to this arbitration provision, you are giving up the right to resolve disputes in court and are accepting the risks associated with arbitration as the exclusive means to resolve any disputes with us.
        </p>
        <h3>13. Governing Law</h3>
        <p>
          These terms of service are governed by and construed in accordance with the laws of Australia. You agree that any legal action or proceeding arising out of or related to these terms shall be brought exclusively in the courts located within Australia.
        </p>

        <h3>14. Severability</h3>
        <p>
          If any provision of these terms is found to be unenforceable or invalid, that provision will be limited or eliminated to the minimum extent necessary so that these terms will otherwise remain in full force and effect.
        </p>

        <h3>15. Contact Information</h3>
        <p>
          For questions, concerns, or inquiries regarding these terms, please contact us at <a href="mailto:legal@earf.tv">legal@earf.tv</a>.
        </p>

        <p>
          <strong>Modifications to Terms:</strong> We reserve the right to modify these terms at any time. Any changes or updates to these terms will be posted on this page along with the effective date of the revisions. Your continued use of the Website after the posting of changes constitutes your acceptance of such changes.
        </p>
      </div>
    </div>
  );
};

export default TermsOfService;
