import React, { useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Earthquakes.css'; // Custom styling for earthquakes

const Earthquakes = () => {
  const [earthquakeList, setEarthquakeList] = useState([]);
  const [highestMagnitudeQuake, setHighestMagnitudeQuake] = useState(null);
  const [loading, setLoading] = useState(true); // Start with loading
  const [initialLoad, setInitialLoad] = useState(true); // Track if it's the first load
  const [latestQuakeId, setLatestQuakeId] = useState(null); // Track the latest earthquake ID
  const intervalRef = useRef(null); // Ref to hold interval ID

  // Fetch Earthquake data from USGS
  const fetchEarthquakeData = async () => {
    setLoading(true); // Start loading state
    try {
      const response = await fetch(
        'https://earthquake.usgs.gov/fdsnws/event/1/query?format=geojson&orderby=time&limit=500'
      );
      const data = await response.json();
      const now = new Date();
      const threeDaysAgo = now.getTime() - 3 * 24 * 60 * 60 * 1000;

      const recentEarthquakes = data.features.filter((quake) =>
        new Date(quake.properties.time) >= threeDaysAgo
      );

      if (recentEarthquakes.length === 0) {
        return;
      }

      const latestQuake = recentEarthquakes[0];
      
      // Show toast notification if a new earthquake is detected (only after the first load)
      if (!initialLoad && latestQuake.id !== latestQuakeId) {
        toast.warn('New Earthquake Detected!', {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
        });
        setLatestQuakeId(latestQuake.id); // Update the latest quake ID
      }

      setEarthquakeList(recentEarthquakes);
      
      // Update the highest magnitude quake
      const highestMagnitude = recentEarthquakes.reduce((max, quake) =>
        quake.properties.mag > max.properties.mag ? quake : max
      );
      setHighestMagnitudeQuake(highestMagnitude);

      setLoading(false);
      setInitialLoad(false); // After first load
    } catch (error) {
      console.error('Error fetching earthquake data:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEarthquakeData(); // Initial load

    // Set up interval for refreshing every 30 seconds
    intervalRef.current = setInterval(() => {
      fetchEarthquakeData();
    }, 30000);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalRef.current);
  }, []); // Empty dependency array ensures it only runs once on mount

  // Helper function to format the earthquake time
  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString(); // Formats to local date and time
  };

  // Helper function to calculate time ago
  const timeAgo = (timestamp) => {
    const now = new Date();
    const secondsAgo = Math.floor((now - timestamp) / 1000);

    if (secondsAgo < 60) {
      return `${secondsAgo} seconds ago`;
    } else if (secondsAgo < 3600) {
      const minutes = Math.floor(secondsAgo / 60);
      return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
    } else if (secondsAgo < 86400) {
      const hours = Math.floor(secondsAgo / 3600);
      return `${hours} hour${hours > 1 ? 's' : ''} ago`;
    } else {
      const days = Math.floor(secondsAgo / 86400);
      return `${days} day${days > 1 ? 's' : ''} ago`;
    }
  };

  // Render the loader
  const renderLoader = () => (
    <div className="full-container">
    <div className="spinner-wrapper">
      <div className="spinner"></div> {/* Spinner Element */}
    </div>
  </div>
  );

  // Render the list of earthquakes
  const renderEarthquakeList = () => (
    <div className="earthquake-list">
      {earthquakeList
        .filter((quake) => quake !== highestMagnitudeQuake) // Exclude the highest quake
        .map((quake, index) => (
          <div key={index} className="earthquake-card">
            <div className="earthquake-card-content">
              <div className="earthquake-info">
                <h3 className="earthquake-title">
                  {quake.properties.mag} Magnitude |<span style={{ color: 'var(--earf-blue)' }}> {quake.properties.place}</span>

                </h3>
                <p className="earthquake-time">
                  {formatDate(quake.properties.time)} (
                  {timeAgo(new Date(quake.properties.time))})
                </p>
              </div>
              <a
                href={quake.properties.url}
                target="_blank"
                rel="noopener noreferrer"
                className="earthquake-more-info-button"
              >
                More Info
              </a>
            </div>
          </div>
        ))}
      {loading && renderLoader()} {/* Show loader while loading */}
    </div>
  );

  // Render the "Highest Recently" section
  const renderHighestRecently = () => {
    if (!highestMagnitudeQuake) return null;

    return (
      <div className="highest-recently">
        <div className="highest-recently-card">
          <div className="earthquake-info">
            <h3 className="highest-earthquake-title">
              {highestMagnitudeQuake.properties.mag} Magnitude |{' '}
              <span style={{ color: 'var(--earf-blue)' }}> {highestMagnitudeQuake.properties.place}</span>
            </h3>
            <p className="highest-earthquake-time">
              {formatDate(highestMagnitudeQuake.properties.time)} (
              {timeAgo(new Date(highestMagnitudeQuake.properties.time))})
            </p>
          </div>
          <a
            href={highestMagnitudeQuake.properties.url}
            target="_blank"
            rel="noopener noreferrer"
            className="earthquake-more-info-button"
          >
            More Info
          </a>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="earthquake-header">
      <h2>Earthquake <span style={{ color: 'var(--earf-blue)' }}>Alerts</span></h2>

        {renderHighestRecently()}
      </div>
      {renderEarthquakeList()} {/* Render the list of earthquakes */}
    </div>
  );
};

export default Earthquakes;
