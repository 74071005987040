import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styles from './Sidebar.module.css';
import Loader from '../Loader/Loader';

const Sidebar = ({ collapsed }) => {
  const [popularLocations, setPopularLocations] = useState([]);
  const location = useLocation();
  const tag = location.state?.tag;

  const browsePath = location.pathname.includes('/stream/') && tag
    ? `/browse/${encodeURIComponent(tag)}`
    : '/browse';

  useEffect(() => {
    const fetchPopularLocations = async () => {
      try {
        // Fetch popular countries from the backend route
        const response = await fetch('https://server.earf.tv/popular-countries', {
          method: 'GET',
          headers: {
            'x-api-key': process.env.REACT_APP_EARF_API_KEY,
            'Content-Type': 'application/json',
            'X-Request-Source': 'Popular-Countries',

          },
        });

        if (!response.ok) {
          console.error('Failed to fetch popular locations');
          return;
        }

        const data = await response.json();
        setPopularLocations(data.topCountries || []); // Assuming the API returns an array in `topCountries`
      } catch (error) {
        console.error('Error fetching popular locations:', error);
      }
    };

    fetchPopularLocations();
  }, []);

  return (
    <div className={`${styles.sidebar} ${collapsed ? styles.collapsed : ''}`} id="sidebar">
      <div className={styles['sidebar-nav']}>
        {/* Link to Camera Browse (/browse) */}
        <Link
          to={browsePath}
          className={`${styles['sidebar-menu-item']}`}
          id="browse-button"
          style={{ textDecoration: 'none' }}
        >
          <i className="fa-solid fa-briefcase"></i>
          {!collapsed && <span>Browse</span>}
        </Link>
        {/* Link to Radio Browse (/radio) */}
        <Link to="/radio" className={styles['sidebar-menu-item']} id="radio-button">
          <i className="fa-solid fa-radio"></i>
          {!collapsed && <span>Radio</span>}
        </Link>

        {/* Link to Map View (/) */}
        <Link to="/explore" className={styles['sidebar-menu-item']} id="map-button">
          <i className="fa-solid fa-map"></i>
          {!collapsed && <span>Map</span>}
        </Link>
      </div>

      <div className={styles['section-divider']}></div>

      {/* Popular Locations Section */}
      <div className={`${styles['popular-section']} ${collapsed ? styles.collapsed : ''}`}>
        <div className={styles['left-section']}>
          {!collapsed && <h2>Popular Locations</h2>}
        </div>
        <div className={`${styles['collapsed-header']} ${collapsed ? styles.show : ''}`}>
          <i className="fas fa-heart"></i>
        </div>
      </div>

      <div className={styles['sidebar-items']} id="sidebar1">
  {popularLocations.length > 0 ? (
    popularLocations.map((location) => {
      // Dynamically construct the flag URL using the country code (iso_code)
      const countryCode = location.profile.properties.iso_code.toLowerCase();
      const flagUrl = `https://flagcdn.com/w320/${countryCode}.png`;

      return (
        <Link
          key={location.country}
          to={`/browse/${encodeURIComponent(location.country)}`}
          className={styles['country-item']}
        >
          <img
            src={flagUrl}
            alt={`${location.country} Flag`}
            className={styles['flag-icon']}
          />
          <div className={styles['browsetext-container']}>
            <span className={styles['country-name']}>{location.country}</span>
            <span className={styles['region-name']}>
              {location.profile.properties.subregion || 'Unknown Region'}
            </span>
          </div>
        </Link>
      );
    })
  ) : (
    <Loader />
     )}
</div>

    </div>
  );
};

export default Sidebar;
