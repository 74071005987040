import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import styles from './Footer.module.css';


const Footer = () => {
    return (
<footer className={styles['footer-container']}>
<img src={window.logoUrl} alt="Earf.tv Logo" className={styles.logo} />

    <div className={styles['footer-logo']}>
        
        <span className={styles['fancy-title-span']}>EARF</span>
        <span className={styles['fancy-title-dot']}>.</span>TV
    </div>

    <div className={styles['footer-links']}>
        <div className={styles['footer-section']}>
            <h4>Our Policy</h4>
            <ul>
                <li><Link to="/disclaimer">DMCA & Copyright</Link></li>
                <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                <li><Link to="/terms-of-service">Terms of Service</Link></li>
                <li><Link to="/help-support">Help & Support</Link></li>
            </ul>
        </div>

        <div className={styles['footer-section']}>
            <h4>Resources</h4>
            <ul>
                <li><span>Support </span><a href="mailto:support@earf.tv">support@earf.tv</a></li>
                <li><span>Partners </span><a href="mailto:partners@earf.tv">partners@earf.tv</a></li>
                <li><span>Legal </span><a href="mailto:legal@earf.tv">legal@earf.tv</a></li>
            </ul>
        </div>
    </div>

    <div className={styles['footer-social']}>
        <a href="https://x.com/EarfTV" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-twitter"></i>
        </a>
        <a href="https://instagram.com/earftv" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-instagram"></i>
        </a>
        <a href="https://www.facebook.com/profile.php?id=61567493698920" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-facebook"></i>
        </a>
    </div>
</footer>

    );
};

export default Footer;
