import React from 'react';

const Weather = () => {
  return (
    <div>
      <div className="header-container">
        <h2>Weather Alerts</h2>
      </div>
      <p>Coming Soon</p>
      <p>Check Back Later</p>
    </div>
  );
};

export default Weather;
