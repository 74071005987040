import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Disclaimer.css'; // CSS file for styling
import { Helmet } from 'react-helmet-async';

const Disclaimer = () => {
  const navigate = useNavigate();

  return (
    <div className="disclaimer-container">
      <Helmet prioritizeSeoTags>
        {/* Page Title */}
        <title>DMCA & Copyright Disclaimer | Earf</title>

        {/* Meta Description */}
        <meta
          name="description"
          content="Read Earf's DMCA & Copyright Disclaimer to understand our process for handling copyright infringement claims and protecting intellectual property rights on our platform."
        />

        {/* Canonical Tag */}
        <link rel="canonical" href="https://earf.tv/dmca-copyright-disclaimer" />

        {/* Open Graph Meta Tags */}
        <meta property="og:title" content="DMCA & Copyright Disclaimer | Earf" />
        <meta
          property="og:description"
          content="Learn how Earf manages copyright infringement claims under the DMCA and our commitment to protecting intellectual property rights on our live-streaming platform."
        />
        <meta property="og:image" content="https://earf.tv/metaimage.png" />
        <meta property="og:url" content="https://earf.tv/dmca-copyright-disclaimer" />
        <meta property="og:type" content="website" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="DMCA & Copyright Disclaimer | Earf" />
        <meta
          name="twitter:description"
          content="Understand how Earf handles copyright infringement claims under the DMCA and protects intellectual property rights across our platform."
        />
        <meta name="twitter:image" content="https://earf.tv/metaimage.png" />
        <meta name="twitter:image:alt" content="Earf DMCA and Copyright Disclaimer" />
      </Helmet>


      {/* Header with Back Button (fixed at the top) */}
      <div className="header-container">
        <h1 className="disclaimer-header">DMCA & Copyright Disclaimer</h1>
        <button className="back-button" onClick={() => navigate(-1)}>
          Back
        </button>
      </div>

      {/* Scrollable content section */}
      <div className="disclaimer-content">
        <p style={{ fontSize: '16px' }}>
          <strong>Effective Date:</strong> October 2024
        </p>


        {/* DMCA & Copyright Policy */}
        <p>
          Earf, through its platform <strong>Earf.TV</strong>, previously known as <strong>Interactive.Earth</strong>, is committed to respecting the intellectual property rights of others. This DMCA & Copyright Policy governs the procedures for handling copyright infringement claims on the Website.
        </p>

        <p>
          References to "we," "us," and "our" refer to <strong>Earf</strong>, <strong>Earf.TV</strong>, and <strong>Interactive.Earth</strong>, including their subdomains and related services. By using the Website, you agree to comply with this policy.
        </p>

        <p>
          By accessing or using <strong>Earf</strong> (the "Website"), including any domains or subdomains associated with <strong>Earf.TV</strong> and <strong>Interactive.Earth</strong>, you confirm that you have read, understood, and agreed to this DMCA & Copyright Policy. If you do not agree, please discontinue your use of the Website immediately.
        </p>
        <p>
          Earf complies with the Digital Millennium Copyright Act of 1998 (DMCA). If you own a copyright or have authority to act on behalf of a copyright owner and want to report a claim that Earf or a third party is infringing on your material through Earf services, please send a notice that meets the minimum requirements of the DMCA to <a href="mailto:legal@earf.tv">legal@earf.tv</a>.
        </p>

        <h3>Protection of Intellectual Property</h3>

        <p>
          All proprietary content, design, layout, graphics, and code created by or for Earf, including Earf.TV and Interactive.Earth, are protected by intellectual property laws and are the exclusive property of Earf. This protection applies to all elements of the websites and services not covered by third-party rights, such as external videos or radio streams.
        </p>

        <p>
          Any unauthorized reproduction, copying, modification, distribution, or exploitation of the intellectual property owned by Earf, whether found on Earf.TV, Interactive.Earth, or their subdomains, is expressly prohibited. Violators will face swift legal action. This includes, but is not limited to, the unauthorized use of Earf's website designs, code, logos, graphics, and other proprietary materials.
        </p>

        <p>
          Earf reserves the right to pursue all available legal remedies against individuals or entities that infringe on its intellectual property rights. This includes immediate legal proceedings for injunctions, claims for damages, and the pursuit of civil or criminal penalties where applicable. Any individual or entity found in violation may be liable for significant monetary damages, including legal fees, and may face additional penalties under intellectual property law.
        </p>

        <p>
          Earf’s intellectual property rights will be enforced to their fullest extent, and any infringement will be prosecuted in the appropriate legal jurisdiction. We take all violations seriously and will take necessary legal measures to protect our rights.
        </p>

        <h3>Submitting a DMCA Notice</h3>

        <p>
          If you are a copyright owner or authorized agent and believe that material available on Earf, including Earf.TV or Interactive.Earth, infringes on your copyright, you may submit a DMCA notification by providing our designated copyright agent with the following information in writing:
        </p>

        <ul>
          <li>A physical or electronic signature of a person authorized to act on behalf of the owner of the copyrighted work.</li>
          <li>Identification of the copyrighted work claimed to have been infringed, or, if multiple works are covered by a single notification, a representative list of such works.</li>
          <li>Identification of the material that is claimed to be infringing or to be the subject of infringing activity and that is to be removed or access to which is to be disabled, and information reasonably sufficient to permit us to locate the material.</li>
          <li>Information reasonably sufficient to permit us to contact you, such as an address, telephone number, and email address.</li>
          <li>A statement that you have a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law.</li>
          <li>A statement that the information in the notification is accurate, and under penalty of perjury, that you are authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.</li>
        </ul>

        <p>Please send your DMCA notice to <a href="mailto:legal@earf.tv">legal@earf.tv</a>.</p>

        <h3>Submitting a DMCA Counter-Notice</h3>

        <p>
          If you believe that material has been removed or disabled by mistake or misidentification, you may submit a counter-notification in writing to our designated agent. Your counter-notification must include the following:
        </p>

        <ul>
          <li>Your physical or electronic signature.</li>
          <li>Identification of the material that has been removed or to which access has been disabled and the location at which the material appeared before it was removed or access was disabled.</li>
          <li>A statement under penalty of perjury that you have a good faith belief that the material was removed or disabled as a result of mistake or misidentification.</li>
          <li>Your name, address, telephone number, and email address.</li>
        </ul>

        <p>Please send your DMCA counter-notice to <a href="mailto:legal@earf.tv">legal@earf.tv</a>.</p>

        <h3>Trademark Takedown Requests</h3>

        <p>
          If you believe that content on Earf.TV or Interactive.Earth infringes on your trademark rights, please provide the following information to <a href="mailto:legal@earf.tv">legal@earf.tv</a>:
        </p>

        <ul>
          <li>Details of the original trademark (including a description if it's a logo).</li>
          <li>List of countries where the trademark is registered.</li>
          <li>Registration number(s) of the trademark.</li>
          <li>Scanned copy of the trademark registration certificate(s) or screenshot of the registration on the website or database of the applicable national intellectual property offices.</li>
          <li>A declaration stating that you have a good-faith belief that the reported use is not authorized by the intellectual property rights owner, its agent, or the law.</li>
        </ul>

        <h3>Repeat Infringer Policy</h3>

        <p>
          We maintain a strict policy against repeat infringers. We reserve the right to suspend or terminate accounts and/or block IP addresses of users who repeatedly violate the intellectual property rights of others. This action is taken in accordance with our policies and at our sole discretion.
        </p>

        <p>
          For any inquiries regarding copyright or trademark matters, please contact us at <a href="mailto:legal@earf.tv">legal@earf.tv</a>.
        </p>
      </div>
    </div>

  );
};

export default Disclaimer;
