import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom'; // Import useNavigate for navigation
import AudioPlayer from './AudioPlayer';
import styles from './Header.module.css';


const Header = ({ onCollapseToggle }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const navigate = useNavigate(); // useNavigate hook for navigation

  // Function to toggle the dropdown
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  // Function to handle navigation based on option
  const handleNavigation = (path) => {
    setIsDropdownOpen(false); // Close the dropdown after selection
    navigate(path); // Navigate to the selected route
  };

  return (
    <div className={styles.header}>
      <div className={styles['header-title']}>
      <button className={styles['collapse-btn']} onClick={onCollapseToggle} aria-label="Toggle menu">
  <i className="fa-solid fa-bars"></i>
</button>
        <Link to="/">
        <img src={window.logoUrl} alt="Earfy Logo" className={styles['header-logo']} />

        </Link>

        <div className={styles['title-with-dropdown']}>
          <span className={styles['fancy-title-span']}>
            <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>EARF</Link>
          </span>

          <span className={styles.BETA}>BETA</span>

          <button className={styles['vertical-dots-btn']} onClick={toggleDropdown} aria-label="Open dropdown menu">
  <i className="fa-solid fa-ellipsis-v"></i>
</button>

          {isDropdownOpen && (
            <ul className={styles['dropdown-menu']}>
              <li onClick={() => handleNavigation('/')}>Home</li>
              <li onClick={() => handleNavigation('/camera-request')}>Camera Request</li>
              <li onClick={() => handleNavigation('/changelog')}>Changelog</li>

              <li className={styles['dropdown-header']}>Help and Legal</li>
              <li onClick={() => handleNavigation('/help-support')}>Help and Support</li>
              <li onClick={() => handleNavigation('/privacy-policy')}>Privacy Policy</li>
              <li onClick={() => handleNavigation('/terms-of-service')}>Terms of Service</li>
              <li onClick={() => handleNavigation('/disclaimer')}>Disclaimer and Copyright</li>
            </ul>
          )}
        </div>
      </div>

      <div className={styles['header-middle']} id="geocoder-container"></div>

      <div className={styles['header-right']}>
        <AudioPlayer />
      </div>
    </div>
  );
};

export default Header;
