import React, { useState, useEffect, useRef } from 'react';
import './FBI.css'; // Import custom styling

const FBI = () => {
    const [wantedList, setWantedList] = useState([]);
    const [selectedPerson, setSelectedPerson] = useState(null);
    const [page, setPage] = useState(1); // Track the current page
    const [loading, setLoading] = useState(true); // Start with loading
    const [hasMore, setHasMore] = useState(true); // Track if there are more pages to load
    const listRef = useRef(null); // Ref to the FBI list container

    // Fetch FBI Wanted List from the API
    const fetchFBIData = async (pageNum) => {
        setLoading(true); // Start loading state
        try {
            const response = await fetch(`https://api.fbi.gov/wanted/v1/list?page=${pageNum}`);
            const data = await response.json();

            if (data.items.length === 0) {
                setHasMore(false); // No more pages to load
            } else {
                setWantedList(data.items); // Replace items with the current page
            }
        } catch (error) {
            console.error('Error fetching FBI data:', error);
        } finally {
            setLoading(false); // Reset loading state
        }
    };

    useEffect(() => {
        fetchFBIData(page); // Fetch the initial page
    }, [page]);

    // Scroll to the top of the list
    const scrollToListTop = () => {
        if (listRef.current) {
            listRef.current.scrollTo({
                top: 0,
                behavior: 'smooth', // Smooth scroll
            });
        }
    };

    // Handle Next Page
    const handleNextPage = () => {
        if (hasMore) {
            setPage((prevPage) => prevPage + 1);
            scrollToListTop(); // Scroll to top after clicking next
        }
    };

    // Handle Previous Page
    const handlePreviousPage = () => {
        if (page > 1) {
            setPage((prevPage) => prevPage - 1);
            scrollToListTop(); // Scroll to top after clicking previous
        }
    };

    // Handle click to show detailed info
    const handlePersonClick = (person) => {
        setSelectedPerson(person);
    };

    // Handle back to list
    const handleBackClick = () => {
        setSelectedPerson(null);
    };

    // Helper function to strip HTML tags from remarks and other fields
    const stripHtmlTags = (input) => {
        const div = document.createElement('div');
        div.innerHTML = input;
        return div.textContent || div.innerText || '';
    };

    // Render the loader
    const renderLoader = () => (
        <div className="full-container">
        <div className="spinner-wrapper">
          <div className="spinner"></div> {/* Spinner Element */}
        </div>
      </div>
    );

    // Render the detailed view
    const renderPersonDetails = () => {
        const person = selectedPerson;

        return (
            <div className="fbi-details">
                {/* Left side - Image */}
                <div className="fbi-left">
                    <div className="fbi-details-image-wrapper">
                        <img
                            src={person.images[0]?.thumb || 'https://via.placeholder.com/150'}
                            alt={person.title}
                            className="fbi-details-image"
                        />
                        {/* "More Info" button links to the FBI page for that person */}
                        <a
                            href={person.url} // Use the person's URL from the API
                            target="_blank"
                            rel="noopener noreferrer"
                            className="fbi-more-info-button"
                        >
                            <img src="/Fbi.png" alt="FBI" className="fbi-more-info-icon" />
                            More Info
                        </a>
                    </div>
                </div>
                {/* Right side - Info */}
                <div className="fbi-details-content">
                    <div className="fbi-details-header">
                        <h3 className="fbi-title">{person.title}</h3>
                        <button onClick={handleBackClick} className="fbi-back-to-list-button">
                            &larr; Back to list
                        </button>
                    </div>
                    {person.warning_message && (
                        <p className="fbi-warning"><strong>{person.warning_message}</strong></p>
                    )}
                    <div className="fbi-details-info">
                        {/* Conditional rendering based on field availability */}
                        {person.age_min && (
                            <p><strong>Age: </strong>{person.age_min} - {person.age_max}</p>
                        )}
                        {person.height_min && person.height_max && (
                            <p><strong>Height: </strong>{person.height_min} - {person.height_max} inches</p>
                        )}
                        {person.weight_min && person.weight_max && (
                            <p><strong>Weight: </strong>{person.weight_min} - {person.weight_max} lbs</p>
                        )}
                        {person.hair_raw && (
                            <p><strong>Hair Color: </strong>{person.hair_raw}</p>
                        )}
                        {person.eyes_raw && (
                            <p><strong>Eye Color: </strong>{person.eyes_raw}</p>
                        )}
                        {person.race_raw && (
                            <p><strong>Race: </strong>{person.race_raw}</p>
                        )}
                        {person.reward_text && (
                            <p><strong>Reward: </strong>{person.reward_text}</p>
                        )}
                        {person.details && (
                            <p><strong>Details: </strong>{stripHtmlTags(person.details)}</p>
                        )}
                        {person.remarks && (
                            <p><strong>Remarks: </strong>{stripHtmlTags(person.remarks)}</p>
                        )}
                        {person.caution && (
                            <p><strong>Caution: </strong>{stripHtmlTags(person.caution)}</p>
                        )}
                        {person.scars_and_marks && (
                            <p><strong>Scars/Marks: </strong>{person.scars_and_marks}</p>
                        )}
                    </div>
                </div>
            </div>
        );
    };

    // Render the list view with minimalistic cards
    const renderWantedList = () => (
        <div className="fbi-list" ref={listRef}>
            {loading ? renderLoader() : wantedList.map((person, index) => (
                <div
                    key={`${person.uid}-${index}`}  // Ensure uniqueness by combining `uid` and `index`
                    className="fbi-card"
                    onClick={() => handlePersonClick(person)}
                >
                    <div className="fbi-card-content">
                        <img src={person.images[0]?.thumb || 'https://via.placeholder.com/100'} alt={person.title} className="fbi-thumbnail" />
                        <div className="fbi-info">
                            <h3 className="fbi-name">{person.title}</h3>
                            <p className="fbi-short-description">{stripHtmlTags(person.description).substring(0, 80)}...</p>
                        </div>
                    </div>
                </div>
            ))}
            {loading}
        </div>
    );

    return (
        <div>
            <div className="fbi-header">
                <h2>
                    <img src="/Fbi.png" alt="FBI Logo" className="fbi-logo" /> FBI Wanted and Missing Persons
                </h2>
                {/* Conditionally render pagination buttons only if no person is selected */}
                {!selectedPerson && (
                <div className="pagination-buttons">
                    {/* Only show the Previous button if the current page is greater than 1 */}
                    {page > 1 && (
                        <button
                            className="prev-page-button"
                            onClick={handlePreviousPage}
                        >
                            &larr; Previous Page
                        </button>
                    )}
                    <button className="next-page-button" onClick={handleNextPage}>
                        Next Page &rarr;
                    </button>
                </div>
            )}
            </div>
            {selectedPerson ? renderPersonDetails() : renderWantedList()} {/* Show details or list based on selection */}
        </div>
    );
};

export default FBI;
