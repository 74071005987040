import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'; // Import useLocation
import { useContext } from 'react';
import { AdminContext } from '../../AdminContext';
import styles from './AdminPanel.module.css'; // Use CSS Modules

const AdminPanel = () => {
  const navigate = useNavigate();
  const location = useLocation(); // Get the current route
  const { isAdminAuthenticated, logoutAdmin } = useContext(AdminContext);
  const [visible, setVisible] = useState(false);

  // Routes where the admin panel should not be shown
  const hiddenRoutes = [
    '/admin/dashboard',
    '/admin/login',
  ];

  useEffect(() => {
    setVisible(isAdminAuthenticated && !hiddenRoutes.includes(location.pathname));
  }, [isAdminAuthenticated, location.pathname]); // Update visibility on route or auth changes

  if (!visible) return null;

  return (
    <div className={styles.adminPanel}>
      <div className={styles.header}>
        <h4 className={styles.title}>Admin Panel</h4>
      </div>
      <button className={styles.button} onClick={() => navigate('/admin/dashboard')}>Dashboard</button>
      <button className={styles.button} onClick={() => navigate('/admin/feature-editor')}>Feature Editor</button>
      <button className={styles.button} onClick={() => navigate('/admin/feature-creator')}>Feature Creator</button>
      <button className={styles.button} onClick={() => navigate('/admin/country-profiles')}>Country Profiles</button>
      <button className={`${styles.button} ${styles.logoutButton}`} onClick={logoutAdmin}>Logout</button>
    </div>
  );
};

export default AdminPanel;
