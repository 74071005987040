import React, { useState, useRef, useEffect, useContext } from 'react';
import { Routes, Route, useNavigate, Navigate, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { PlayerProvider } from './components/context/PlayerContext';
import { AdminProvider, AdminContext } from './components/AdminContext';
import Sidebar from './components/Sidebar/Sidebar';
import Header from './components/Header/Header';
import MobileSidebar from './components/Sidebar/MobileSidebar/MobileSidebar';
import Mapbox from './components/Mapbox/Mapbox';
import AlertSystem from './components/AlertSystem/AlertSystem';
import RadioBrowse from './components/RadioBrowse/RadioBrowse';
import RadioStations from './components/RadioBrowse/RadioStations';
import VideoView from './components/VideoView/VideoView';
import Browse from './components/Browse/Browse';
import PrivacyPolicy from './components/pages/PrivacyPolicy';
import TermsOfService from './components/pages/TermsOfService';
import Disclaimer from './components/pages/Disclaimer';
import Contact from './components/pages/Contact';
import Changelog from './components/pages/Changelog';
import LiveAlerts from './components/pages/LiveAlerts/LiveAlerts';
import Home from './components/pages/Home';
import NotFound from './components/pages/NotFound';
import CameraRequest from './components/pages/CameraRequest';
import AdminLogin from './components/Admin/AdminLogin';
import AdminDashboard from './components/Admin/AdminDashboard';
import StreamCheckerPage from './components/Admin/StreamChecker/StreamChecker';
import StreamCreator from './components/Admin/StreamCreator/StreamCreator';
import CountryEditor from './components/Admin/CountryProfiles/CountryProfiles';

import useWindowSize from './hooks/useWindowSize';
import { ToastContainer } from 'react-toastify';
import AdminPanel from './components/Admin/AdminPanel/AdminPanel';
import './App.css';

function App() {
  const windowSize = useWindowSize();
  const isMobile = windowSize.width <= 768;
  window.logoUrl = '/christmasearfy.png';
  const { isAdminAuthenticated } = useContext(AdminContext); // Use context to check admin status
  // State for sidebar collapse
  const [isCollapsed, setIsCollapsed] = useState(isMobile);  // Initialize based on screen size

  useEffect(() => {
    // Update the collapsed state whenever the window size changes
    setIsCollapsed(isMobile);
  }, [isMobile]);

  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedFeature, setSelectedFeature] = useState(null);
  const [videoDetails, setVideoDetails] = useState(null);
  const [channelDetails, setChannelDetails] = useState(null);
  const [mapState, setMapState] = useState({ center: [0, 0], zoom: 2, pitch: 0, bearing: 0 });

  const navigate = useNavigate();
  const mapRef = useRef(null);
  const rainViewerCleanupRef = useRef(null);
  const location = useLocation();
  const [isMiniPlayerActive, setIsMiniPlayerActive] = useState(false);
  const [miniPlayerFeature, setMiniPlayerFeature] = useState(null);
  useEffect(() => {
    ReactGA.initialize('G-SHLXRDHHQ6');
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search });
  }, []);

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search });
  }, [location]);

  const handleCollapseToggle = () => {
    setIsCollapsed((prevState) => !prevState);
  };

  const handleFeatureClickFromMap = (feature) => {
    if (isMiniPlayerActive) {
      setIsMiniPlayerActive(false);
      setMiniPlayerFeature(null);
    }

    const currentMapState = {
      center: mapRef.current.getCenter().toArray(),
      zoom: mapRef.current.getZoom(),
      pitch: mapRef.current.getPitch(),
      bearing: mapRef.current.getBearing(),
    };

    setMapState(currentMapState);
    setSelectedFeature(feature);
    navigate(`/stream/${feature.properties.videoId}`);
  };

  const handleBackClick = () => {
    if (selectedFeature) {
      setIsMiniPlayerActive(true);
      setMiniPlayerFeature(selectedFeature);
    }
    setSelectedFeature(null);
    setVideoDetails(null);
    setChannelDetails(null);
    navigate('/explore');
  };

  const handleWildfireLocationSelect = (lat, lng, zoom) => {
    navigate('/explore');
    setSelectedLocation({ lat, lng, zoom });
  };

  const handleBrowseBackClick = () => {
    setIsMiniPlayerActive(true);
    navigate(-1);
  };

  const initializeRainViewer = (mapRef) => {
    console.log('RainViewer initialized!');
  };



  const handleCloseSidebar = () => {
    setIsCollapsed(true);
  };

  return (
    <AdminProvider>
      <PlayerProvider>
        <div className={`app-layout ${isMobile ? 'mobile' : ''}`}>
          <Header onCollapseToggle={handleCollapseToggle} />
          <div className="body-layout">
            {isMobile ? (
              <MobileSidebar
                open={!isCollapsed}
                collapsed={isCollapsed}
                onToggle={handleCollapseToggle}
                handleCloseSidebar={handleCloseSidebar}
              />
            ) : (
              <Sidebar collapsed={isCollapsed} />
            )}

            <div className={`main-content ${isCollapsed ? 'collapsed' : 'expanded'}`}>
              {isMiniPlayerActive && miniPlayerFeature && (
                <div className="mini-player">
                  <div className="mini-player-container">
                    <iframe
                      src={`https://www.youtube.com/embed/${miniPlayerFeature.properties.videoId}?autoplay=1&mute=1`}
                      title="Mini YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      className="mini-player-video"
                    ></iframe>
                    <div className="mini-player-controls">
                      <button
                        className="mini-player-enlarge"
                        onClick={() => {
                          setIsMiniPlayerActive(false);
                          navigate(`/stream/${miniPlayerFeature.properties.videoId}`);
                        }}
                      >
                        <i className="fas fa-expand"></i>
                      </button>
                      <button className="mini-player-stop" onClick={() => setIsMiniPlayerActive(false)}>
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              )}
              <AlertSystem />

              <AdminPanel isAdminAuthenticated={isAdminAuthenticated} />
              <ToastContainer
                autoClose={5000}                  // Automatically close toasts after 5 seconds
                hideProgressBar={false}           // Show progress bar
                newestOnTop={false}               // Older toasts show on top
                closeOnClick                      // Close on click
                pauseOnHover                      // Pause when hovered
                draggable                         // Allow dragging to dismiss
                theme="dark"                      // Set theme to dark
                limit={3}                         // Limit to 3 visible toasts at once
                position={window.innerWidth < 768 ? 'bottom-center' : 'top-right'}  // Adjust position for mobile
                style={{
                  width: window.innerWidth < 768 ? '90%' : 'auto', // Adjust width for mobile
                }}
              />
              <Routes>
                <Route
                  path="/explore"
                  element={
                    <Mapbox
                      isCollapsed={isCollapsed}
                      selectedLocation={selectedLocation}
                      onFeatureClick={handleFeatureClickFromMap}
                      mapState={mapState}
                      setMapState={setMapState}
                      setMapRef={(map) => {
                        mapRef.current = map;
                      }}
                    />
                  }
                />
                <Route path="/" element={<Home />} />
                <Route path="*" element={<NotFound />} />
                <Route path="/stream/*" element={<NotFound />} />

                <Route path="/help-support" element={<Contact />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/terms-of-service" element={<TermsOfService />} />
                <Route path="/disclaimer" element={<Disclaimer />} />
                <Route path="/changelog" element={<Changelog />} />
                <Route path="/camera-request" element={<CameraRequest />} />
                <Route path="/radio" element={<RadioBrowse />} />
                <Route path="/radio/:country" element={<RadioStations />} />
                <Route path="/live-alerts/*" element={<LiveAlerts handleWildfireLocationSelect={handleWildfireLocationSelect} />} />
                <Route
                  path="/browse"
                  element={
                    <Browse
                      setIsMiniPlayerActive={setIsMiniPlayerActive}
                      setMiniPlayerFeature={setMiniPlayerFeature}
                      handleBrowseBackClick={handleBrowseBackClick}
                    />
                  }
                />
                <Route
                  path="/browse/:tag"
                  element={
                    <Browse
                      setIsMiniPlayerActive={setIsMiniPlayerActive}
                      setMiniPlayerFeature={setMiniPlayerFeature}
                      handleBrowseBackClick={handleBrowseBackClick}
                    />
                  }
                />
                <Route path="/stream" element={<Navigate to="/browse" />} />
                <Route
                  path="/stream/:videoId"
                  element={
                    <VideoView
                      feature={selectedFeature}
                      videoDetails={videoDetails}
                      channelDetails={channelDetails}
                      handleBackClick={handleBackClick}
                      setMapState={setMapState}
                      setIsMiniPlayerActive={setIsMiniPlayerActive}
                      setMiniPlayerFeature={setMiniPlayerFeature}
                    />
                  }
                />
                <Route path="/admin" element={<Navigate to="/admin/login" />} />
                <Route path="/admin/login" element={<AdminLogin />} />
                <Route path="/admin/dashboard" element={<AdminDashboard />} />
                <Route path="/admin/feature-editor" element={<StreamCheckerPage />} />
                <Route path="/admin/feature-creator" element={<StreamCreator />} />
                <Route path="/admin/country-profiles" element={<CountryEditor />} />

              </Routes>

            </div>
          </div>
        </div>
      </PlayerProvider>
    </AdminProvider>
  );
}

export default App;
