import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './CountryInfo.module.css';
import { AiOutlineWarning } from 'react-icons/ai'; // Ensure this is included
const imageIcons = {
  officialWebsite: '/Stream-Assets/website.png',
  tripadvisor: '/Stream-Assets/tripadvisor.png',
  wikipedia: '/Stream-Assets/wikipedia.png',
  wikivoyage: '/Stream-Assets/wikivoyage.png', // Added Wikivoyage icon
  twitter: '/Stream-Assets/twitter.png',
  instagram: '/Stream-Assets/instagram.png',
  youtube: '/Stream-Assets/youtube.png',
  google: '/Stream-Assets/google.png',
  map: '/Stream-Assets/map.png',
};

const tooltipMessages = {
  google: (countryName) => `${countryName} on Google`,
  tripadvisor: (countryName) => `${countryName} on TripAdvisor`,
  wikipedia: (countryName) => `${countryName} on Wikipedia`,
  wikivoyage: (countryName) => `${countryName} on Wikivoyage`, // Added tooltip for Wikivoyage
  youtube: (countryName) => `${countryName} on YouTube`,
  twitter: (countryName) => `${countryName} on Twitter`,
  instagram: (countryName) => `${countryName} on Instagram`,
  officialWebsite: (countryName) => `Official website of ${countryName}`,
  map: () => 'View on Map',
};

const sortLinksByImportance = (links) => {
  const importanceOrder = [
    'map',
    'google',
    'tripadvisor',
    'wikipedia',
    'wikivoyage', // Added Wikivoyage
    'youtube',
    'twitter',
    'instagram',
    'officialWebsite',
  ];
  return links.sort((a, b) => {
    const indexA = importanceOrder.indexOf(a.type);
    const indexB = importanceOrder.indexOf(b.type);
    return indexA - indexB;
  });
};

const CountryInfo = ({ countryName, countryCode }) => {
  const [countryInfo, setCountryInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const handleFeatureClick = (latitude, longitude) => {
    if (isNaN(latitude) || isNaN(longitude)) {
      console.error('Invalid coordinates:', latitude, longitude);
      return;
    }
    navigate(`/explore?lat=${latitude}&lng=${longitude}&zoom=5`);
  };

  useEffect(() => {
    if (!countryCode) {
      setIsLoading(false);
      return;
    }

    const fetchCountryInfo = async () => {
      try {
        const apiKey = process.env.REACT_APP_EARF_API_KEY;

        const response = await fetch(
          `https://server.earf.tv/country-profiles/${countryCode}`, // Fetch only the required country
          {
            headers: {
              'x-api-key': apiKey,
            },
          }
        );

        if (!response.ok) {
          throw new Error('Failed to fetch country profile');
        }

        const country = await response.json();

        const { properties, geometry } = country;

        // Extract coordinates directly from geometry
        const coordinates = geometry?.coordinates || null;

        const links = [];
        if (properties.links) {
          Object.entries(properties.links).forEach(([key, value]) => {
            if (value) {
              links.push({ type: key, url: value });
            }
          });
        }

        // Add "map" link if coordinates are available
        if (coordinates) {
          links.push({
            type: 'map',
            onClick: () => handleFeatureClick(coordinates[1], coordinates[0]), // Use longitude and latitude
          });
        }

        const sortedLinks = sortLinksByImportance(links);

        setCountryInfo({
          ...properties,
          coordinates,
          links: sortedLinks,
        });
      } catch (error) {
        console.error('Error fetching country info:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCountryInfo();
  }, [countryCode]);

  if (isLoading) {
    return (
      <div className={styles.centeredcontainer}>
        <div className={styles.descriptionloader}></div>
      </div>
    );
  }

  if (!countryInfo) {
    return <div>No country information available.</div>;
  }

  return (
    <div className={styles.infocontainer}>
      <div className={styles.topRow}>
        {/* Header: Flag, Country Name, Motto */}
        <div className={styles.headerContent}>
          <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            {countryCode && (
              <img
                src={`https://flagcdn.com/w320/${countryCode}.png`}
                alt={`${countryName} flag`}
                className={styles.flag}
              />
            )}
            <h2>
              {countryName}
              <span className={styles.liveText}> Live</span>
            </h2>
          </div>
          {countryInfo.motto && (
            <p className={styles.motto} title={`${countryName} Motto`}>
              <strong>{countryInfo.motto}</strong>
            </p>
          )}

          {/* Alert */}
          {countryInfo.alert && (
            <div className={styles.alert}>
              <AiOutlineWarning className={styles.alertIcon} />
              <p>{countryInfo.alert}</p>
            </div>
          )}
        </div>

        {/* Buttons aligned to the right */}
        {countryInfo.links && (
          <div className={styles.buttons}>
            {countryInfo.links.map((link, index) =>
              link.type === 'map' ? (
                <button
                  key={index}
                  onClick={link.onClick}
                  className={styles.iconButton}
                  title={tooltipMessages[link.type]()}
                >
                  <img src={imageIcons[link.type]} alt={link.type} />
                  <div className={styles.tooltip}>
                    {tooltipMessages[link.type]()}
                  </div>
                </button>
              ) : (
                <a
                  key={index}
                  href={link.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.iconButton}
                  title={tooltipMessages[link.type](countryName)}
                >
                  <img src={imageIcons[link.type]} alt={link.type} />
                  <div className={styles.tooltip}>
                    {tooltipMessages[link.type](countryName)}
                  </div>
                </a>
              )
            )}
          </div>
        )}
      </div>
      {/* Description */}
      {countryInfo.description && (
        <div className={styles.description}>
          <p>{countryInfo.description}</p>
        </div>
      )}
    </div>
  );
};

export default CountryInfo;
