import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { usePlayerContext } from '../context/PlayerContext';
import './RadioStations.css';
import fallbackImage from './assets/Speaker_Icon_grey.png'; // Import fallback image from assets
import { Helmet } from 'react-helmet-async';
import Facts from '../Facts/Facts'

// Helper function to capitalize the first letter of each word in the country name
const capitalizeCountry = (country) => {
  return country
    .split('-') // Handle hyphenated slugs
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};
// Country codes for Flagcdn
const countryCodes = {
  'Afghanistan': 'af',
  'Albania': 'al',
  'Algeria': 'dz',
  'Andorra': 'ad',
  'Angola': 'ao',
  'Antigua and Barbuda': 'ag',
  'Argentina': 'ar',
  'Armenia': 'am',
  'Australia': 'au',
  'Austria': 'at',
  'Azerbaijan': 'az',
  'Bahamas': 'bs',
  'Bahrain': 'bh',
  'Bangladesh': 'bd',
  'Barbados': 'bb',
  'Belarus': 'by',
  'Belgium': 'be',
  'Belize': 'bz',
  'Benin': 'bj',
  'Bhutan': 'bt',
  'Bolivia': 'bo',
  'Bosnia and Herzegovina': 'ba',
  'Botswana': 'bw',
  'Brazil': 'br',
  'Brunei Darussalam': 'bn',
  'Bulgaria': 'bg',
  'Burkina Faso': 'bf',
  'Burundi': 'bi',
  'Cabo Verde': 'cv',
  'Cambodia': 'kh',
  'Cameroon': 'cm',
  'Canada': 'ca',
  'Central African Republic': 'cf',
  'Chad': 'td',
  'Chile': 'cl',
  'China': 'cn',
  'Colombia': 'co',
  'Comoros': 'km',
  'Congo (Congo-Brazzaville)': 'cg',
  'Congo (Democratic Republic)': 'cd',
  'Costa Rica': 'cr',
  'Croatia': 'hr',
  'Cuba': 'cu',
  'Curaçao': 'cw',
  'Cyprus': 'cy',
  'Czech Republic': 'cz',
  'Denmark': 'dk',
  'Djibouti': 'dj',
  'Dominica': 'dm',
  'Dominican Republic': 'do',
  'Ecuador': 'ec',
  'Egypt': 'eg',
  'El Salvador': 'sv',
  'Equatorial Guinea': 'gq',
  'Eritrea': 'er',
  'Estonia': 'ee',
  'Eswatini': 'sz',
  'Ethiopia': 'et',
  'Fiji': 'fj',
  'Finland': 'fi',
  'France': 'fr',
  'Gabon': 'ga',
  'Gambia': 'gm',
  'Georgia': 'ge',
  'Germany': 'de',
  'Ghana': 'gh',
  'Greece': 'gr',
  'Grenada': 'gd',
  'Guatemala': 'gt',
  'Guinea': 'gn',
  'Guinea-Bissau': 'gw',
  'Guyana': 'gy',
  'Haiti': 'ht',
  'Honduras': 'hn',
  'Hungary': 'hu',
  'Iceland': 'is',
  'India': 'in',
  'Indonesia': 'id',
  'Iran': 'ir',
  'Iraq': 'iq',
  'Ireland': 'ie',
  'Israel': 'il',
  'Italy': 'it',
  'Jamaica': 'jm',
  'Japan': 'jp',
  'Jordan': 'jo',
  'Kazakhstan': 'kz',
  'Kenya': 'ke',
  'Kiribati': 'ki',
  'Kuwait': 'kw',
  'Kyrgyzstan': 'kg',
  'Laos': 'la',
  'Latvia': 'lv',
  'Lebanon': 'lb',
  'Lesotho': 'ls',
  'Liberia': 'lr',
  'Libya': 'ly',
  'Liechtenstein': 'li',
  'Lithuania': 'lt',
  'Luxembourg': 'lu',
  'Madagascar': 'mg',
  'Malawi': 'mw',
  'Malaysia': 'my',
  'Maldives': 'mv',
  'Mali': 'ml',
  'Malta': 'mt',
  'Marshall Islands': 'mh',
  'Mauritania': 'mr',
  'Mauritius': 'mu',
  'Mexico': 'mx',
  'Micronesia': 'fm',
  'Moldova': 'md',
  'Monaco': 'mc',
  'Mongolia': 'mn',
  'Montenegro': 'me',
  'Morocco': 'ma',
  'Mozambique': 'mz',
  'Myanmar': 'mm',
  'Namibia': 'na',
  'Nauru': 'nr',
  'Nepal': 'np',
  'Netherlands': 'nl',
  'New Zealand': 'nz',
  'Nicaragua': 'ni',
  'Niger': 'ne',
  'Nigeria': 'ng',
  'North Korea': 'kp',
  'North Macedonia': 'mk',
  'Norway': 'no',
  'Oman': 'om',
  'Pakistan': 'pk',
  'Palau': 'pw',
  'Panama': 'pa',
  'Papua New Guinea': 'pg',
  'Paraguay': 'py',
  'Peru': 'pe',
  'Philippines': 'ph',
  'Poland': 'pl',
  'Portugal': 'pt',
  'Qatar': 'qa',
  'Romania': 'ro',
  'Russia': 'ru',
  'Rwanda': 'rw',
  'Saint Kitts and Nevis': 'kn',
  'Saint Lucia': 'lc',
  'Saint Vincent and the Grenadines': 'vc',
  'Samoa': 'ws',
  'San Marino': 'sm',
  'Sao Tome and Principe': 'st',
  'Saudi Arabia': 'sa',
  'Senegal': 'sn',
  'Serbia': 'rs',
  'Seychelles': 'sc',
  'Sierra Leone': 'sl',
  'Singapore': 'sg',
  'Slovakia': 'sk',
  'Slovenia': 'si',
  'Solomon Islands': 'sb',
  'Somalia': 'so',
  'South Africa': 'za',
  'South Korea': 'kr',
  'South Sudan': 'ss',
  'Spain': 'es',
  'Sri Lanka': 'lk',
  'Sudan': 'sd',
  'Suriname': 'sr',
  'Sweden': 'se',
  'Switzerland': 'ch',
  'Syria': 'sy',
  'Taiwan': 'tw',
  'Tajikistan': 'tj',
  'Tanzania': 'tz',
  'Thailand': 'th',
  'Timor-Leste': 'tl',
  'Togo': 'tg',
  'Tonga': 'to',
  'Trinidad and Tobago': 'tt',
  'Tunisia': 'tn',
  'Turkey': 'tr',
  'Turkmenistan': 'tm',
  'Tuvalu': 'tv',
  'Uganda': 'ug',
  'Ukraine': 'ua',
  'United Arab Emirates': 'ae',
  'United Kingdom': 'gb',
  'United States': 'us',
  'Uruguay': 'uy',
  'Uzbekistan': 'uz',
  'Vanuatu': 'vu',
  'Vatican City': 'va',
  'Venezuela': 've',
  'Vietnam': 'vn',
  'Yemen': 'ye',
  'Zambia': 'zm',
  'Zimbabwe': 'zw'
};
const RadioStations = () => {
  const { country } = useParams(); // Get the country from URL params
  const navigate = useNavigate(); // Use navigate for consistent back navigation
  const [stations, setStations] = useState([]);
  const [filteredStations, setFilteredStations] = useState([]);
  const [currentBatch, setCurrentBatch] = useState(50);
  const [loading, setLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false); // Track if the image has loaded
  const { playStation } = usePlayerContext();
  const observerRef = useRef(null);
  const [searchQuery, setSearchQuery] = useState("");
  const maxTags = 3; // Define the maximum number of tags to display (adjustable)

  // Capitalize the country name for display purposes
  const formattedCountry = capitalizeCountry(country);

  useEffect(() => {
    setLoading(true);

    // Fetch radio stations based on the country parameter
    fetch(`https://de1.api.radio-browser.info/json/stations/bycountry/${formattedCountry}`)
      .then(response => {
        if (!response.ok) {
          throw new Error(`Failed to fetch stations: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        setStations(data);
        setFilteredStations(data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching stations:', error);
        setLoading(false);
      });
  }, [country]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        const target = entries[0];
        if (target.isIntersecting) {
          loadNextBatch();
        }
      },
      { threshold: 1.0 }
    );

    if (observerRef.current) {
      observer.observe(observerRef.current);
    }

    return () => {
      if (observerRef.current) {
        observer.unobserve(observerRef.current);
      }
    };
  }, [filteredStations]);

  const loadNextBatch = () => {
    if (currentBatch < filteredStations.length) {
      setCurrentBatch(prevBatch => prevBatch + 50);
    }
  };

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
  
    const results = stations.filter(station => {
      const stationName = station.name.toLowerCase();
      const stationTags = station.tags ? station.tags.toLowerCase() : ''; // Convert tags to lowercase
  
      // Check if the query is in the station name or in the station tags
      return stationName.includes(query) || stationTags.includes(query);
    });
  
    setFilteredStations(results);
    setCurrentBatch(50); // Reset to the initial batch
  };
  
// Helper function to capitalize the first letter of each word in the tag
const capitalizeTag = (tag) => {
  return tag
    .split(' ') // Split the tag into words
    .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
    .join(' '); // Rejoin the words into a string
};

  return (
    <div className="content-wrapper">
      <Helmet prioritizeSeoTags>
        <title>{`${formattedCountry || 'Radio'} Radio | Earf`}</title>
        <meta
          name="description"
          content={`Discover and listen to live radio stations from ${formattedCountry || 'the world'}. Explore a wide variety of genres and stations broadcasting directly from ${formattedCountry || 'different regions'}, only on Earf.tv.`}
        />
        <link rel="canonical" href={`https://earf.tv/radio/${country}`} />
        <meta property="og:title" content={`${formattedCountry || 'Radio'} Radio | Earf`} />
        <meta
          property="og:description"
          content={`Enjoy live radio stations from ${formattedCountry || 'the world'} on Earf. Tune in to popular broadcasts and explore more live radio from around the world on Earf.tv.`}
        />
        <meta
          property="og:image"
          content={countryCodes[formattedCountry]
            ? `https://flagcdn.com/w320/${countryCodes[formattedCountry]}.png`
            : 'https://earf.tv/metaimage.png'}
        />
        <meta property="og:url" content={`https://earf.tv/radio/${country}`} />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={`${formattedCountry || 'Radio'} Radio | Earf`} />
        <meta
          name="twitter:description"
          content={`Listen to live radio stations from ${formattedCountry || 'the world'} on Earf. Explore the best of local and international broadcasts, right from ${formattedCountry || 'different regions'}, on Earf.tv.`}
        />
        <meta name="twitter:image" content="https://earf.tv/metaimage.png" />
      </Helmet>

      {loading ? (
             <div className="full-container">
             <div className="spinner-wrapper">
               <div className="spinner"></div> {/* Spinner Element */}
             </div>
             <Facts />
           </div>
      ) : (
        <>
          <div className="radio-header">
            <h2 className="radio-title">
              {countryCodes[formattedCountry] && (
                <img
                  src={`https://flagcdn.com/w320/${countryCodes[formattedCountry]}.png`}
                  alt={`${formattedCountry} flag`}
                  className="country-flag-title"
                />
              )}
              {formattedCountry} <span style={{ color: 'rgba(104, 223, 223, 0.733)' }}>Radio</span>
            </h2>
            <input
              type="text"
              placeholder="Search"
              value={searchQuery}
              onChange={handleSearch}
              className="search-input"
            />
            <button onClick={() => navigate(-1)} className="back-button">
              Back
            </button>
          </div>

          <div className="scrollable-content">
            <div className="station-list">
              {filteredStations.slice(0, currentBatch).map((station) => {
                const stationTags = station.tags ? station.tags.split(',').slice(0, maxTags) : []; // Extract tags and limit to maxTags

                return (
                  <div
                    key={station.stationuuid}
                    className="radio-station-item"
                    onClick={() => playStation(station.url, station.favicon, station.name)}
                  >
                    <div className="feature-thumbnail">
                      <img
                        src={station.favicon || fallbackImage} // Use station favicon or fallback
                        alt={`${station.name} logo`}
                        className={`favicon-icon ${isLoaded ? 'loaded' : 'loading'}`} // Add classes based on load state
                        loading="lazy" // Lazy loading
                        onLoad={() => setIsLoaded(true)} // When the image has fully loaded
                        onError={(e) => {
                          e.target.onerror = null; // Prevent infinite onError loop
                          e.target.src = fallbackImage; // Fallback image on error
                        }}
                      />
                      {!isLoaded && <div className="image-placeholder"></div>} {/* Show placeholder until image loads */}
                    </div>

                    <div className="text-backdrop">
                      {station.name}

                     {/* Display tags under the station name */}
  <div className="station-tags">
    {stationTags.map((tag, index) => (
      <span key={index} className="station-tag">
        {capitalizeTag(tag.trim())} {/* Capitalize each tag */}
      </span>
    ))}
  </div>
                    </div>
                  </div>
                );
              })}
              <div ref={observerRef} className="load-more-target"></div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default RadioStations;