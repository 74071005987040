import React, { useState, useEffect } from 'react';
import styles from './ServerStatus.module.css';

const ServerStatus = () => {
  const [serverStatus, setServerStatus] = useState({
    status: 'offline', // Default to offline
    uptime: { days: 0, hours: 0, minutes: 0, secs: 0 },
    memoryUsage: { rss: 'N/A', heapUsed: 'N/A', heapTotal: 'N/A' },
    cpuLoad: [0, 0, 0],
    environment: 'N/A',
    serverUrl: 'server.earf.tv', 
  });

  // Function to fetch server status from the API
  const fetchServerStatus = async () => {
    try {
      const response = await fetch('https://server.earf.tv/server-status', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': process.env.REACT_APP_EARF_API_KEY,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch server status');
      }

      const data = await response.json();
      setServerStatus(data);
    } catch (error) {
      console.error('Error fetching server status:', error);
      setServerStatus((prevStatus) => ({
        ...prevStatus,
        status: 'offline',
      }));
    }
  };

  useEffect(() => {
    // Fetch server status when component mounts
    fetchServerStatus();

    // Set up interval to fetch server status every 10 seconds
    const interval = setInterval(fetchServerStatus, 10000);

    // Cleanup interval when component unmounts
    return () => clearInterval(interval);
  }, []); 

  // Update the uptime counter every second
  useEffect(() => {
    const interval = setInterval(() => {
      setServerStatus((prevStatus) => {
        if (prevStatus.status === 'online') {
          const { days, hours, minutes, secs } = prevStatus.uptime;
          let newSecs = secs + 1;
          let newMinutes = minutes;
          let newHours = hours;
          let newDays = days;

          if (newSecs === 60) {
            newSecs = 0;
            newMinutes += 1;
          }
          if (newMinutes === 60) {
            newMinutes = 0;
            newHours += 1;
          }
          if (newHours === 24) {
            newHours = 0;
            newDays += 1;
          }

          return {
            ...prevStatus,
            uptime: { days: newDays, hours: newHours, minutes: newMinutes, secs: newSecs },
          };
        }
        return prevStatus;
      });
    }, 1000); 

    return () => clearInterval(interval); 
  }, [serverStatus.status]);

  const capitalizeEnvironment = (env) => {
    return env.charAt(0).toUpperCase() + env.slice(1); 
  };

  return (
    <div className={styles['status-container']}>
      <div className={styles['status-row']}>
        <div className={serverStatus.status === 'online' ? styles['green-circle'] : styles['red-circle']}></div>
        <span className={styles['status-text']}>
          {serverStatus.status === 'online' ? 'Online' : 'Offline'}
        </span>
        <span className={styles['server-url']}>
          <a href={`https://${serverStatus.serverUrl}`} target="_blank" rel="noopener noreferrer" className={styles['url-link']}>
            {serverStatus.serverUrl}
          </a>
        </span>
      </div>
      {serverStatus.status === 'online' && (
        <div className={styles['server-details']}>
          <p>{capitalizeEnvironment(serverStatus.environment)}</p>
          <p>
            Uptime: {serverStatus.uptime.days}d {serverStatus.uptime.hours}h {serverStatus.uptime.minutes}m {serverStatus.uptime.secs}s
          </p>
          <p>CPU: {serverStatus.cpuLoad[0]} | {serverStatus.cpuLoad[1]} | {serverStatus.cpuLoad[2]}</p>
          <p>Memory: {serverStatus.memoryUsage.heapUsed} / {serverStatus.memoryUsage.heapTotal}</p>
        </div>
      )}
    </div>
  );
};

export default ServerStatus;
