import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import LocationInfo from '../LocationInfo/LocationInfo';
import StreamDescription from '../StreamDescription/StreamDescription';
import './VideoView.css';
import SimilarVideosSidebar from '../SimilarVideosSidebar/SimilarVideosSidebar';
import { Helmet } from 'react-helmet-async';
import ReactGA from 'react-ga4';
import Facts from '../Facts/Facts'

const VideoView = ({
  feature,
  setIsMiniPlayerActive,
  setMiniPlayerFeature,
  setMapState,
}) => {
  const { videoId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [localFeature, setLocalFeature] = useState(feature);
  const [placeQid, setPlaceQid] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const fetchFeature = async () => {
      try {
        let foundFeature = feature || null;

        if (!feature) {
          const liveResponse = await fetch('https://server.earf.tv/live-cameras', {
            method: 'POST',
            headers: {
              'x-api-key': process.env.REACT_APP_EARF_API_KEY,
              'Content-Type': 'application/json',
              'X-Request-Source': 'Stream-View-Live',
            },
          });
          const liveData = await liveResponse.json();
          foundFeature = liveData.features.find((f) => f.properties.videoId === videoId);

          if (!foundFeature) {
            const browseResponse = await fetch('https://server.earf.tv/browse-cameras', {
              method: 'POST',
              headers: {
                'x-api-key': process.env.REACT_APP_EARF_API_KEY,
                'Content-Type': 'application/json',
                'X-Request-Source': 'Stream-View-Browse',

              },
            });
            const browseData = await browseResponse.json();
            foundFeature = browseData.features.find((f) => f.properties.videoId === videoId);
          }
        }

        if (foundFeature) {
          setLocalFeature(foundFeature);

          // Extract QID and country code
          const qid = `${foundFeature.properties.qid}`;
          setPlaceQid(qid);

          const code = foundFeature.properties.country_code;
          setCountryCode(code);
        } else {
          navigate('/404');
        }
      } catch (error) {
        console.error('Error fetching feature:', error);
        navigate('/404');
      } finally {
        setIsLoading(false);
      }
    };

    if (!isMobile) {
      setIsMiniPlayerActive(false);
      setMiniPlayerFeature(null);
    }

    fetchFeature();
  }, [feature, videoId, isMobile, setIsMiniPlayerActive, setMiniPlayerFeature, navigate]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleVideoPlay = () => {
    ReactGA.event({
      category: 'Video',
      action: 'Play',
      label: videoId,
    });
  };

  const handleTagClick = (tag) => {
    navigate(`/browse/${encodeURIComponent(tag)}`);
  };
  // Function to navigate to the map with coordinates
  const handleFeatureClick = (latitude, longitude, zoom = 15) => {
    if (isNaN(latitude) || isNaN(longitude)) {
      console.error('Invalid coordinates:', latitude, longitude);
      return;
    }

    if (isNaN(zoom)) {
      console.warn('Invalid zoom level, defaulting to 15.');
      zoom = 15;
    }

    navigate(`/explore?lat=${latitude}&lng=${longitude}&zoom=${zoom}`);
  };


  if (isLoading) {
    return (
      <div className="full-container">
        <div className="spinner-wrapper">
          <div className="spinner"></div>
        </div>
        <Facts />
      </div>
    );
  }

  const coordinates = localFeature?.geometry?.coordinates || null;
  const featureName = localFeature?.properties?.name || 'Stream';
  const videoThumbnail = `https://img.youtube.com/vi/${localFeature?.properties?.videoId}/hqdefault.jpg`;

  return (
    <>
      {localFeature && (
        <>
          <Helmet prioritizeSeoTags>
            <title>{`${featureName} Stream | Earf`}</title>
            <meta
              name="description"
              content={`Watch the live stream of ${featureName}, explore breathtaking locations in ${localFeature?.properties?.wikidata || 'this location'}, and discover more incredible live streams from around the world on Earf.`}
            />
            <link rel="canonical" href={`https://earf.tv/stream/${videoId}`} />
            <meta property="og:title" content={`${featureName} Stream | Earf`} />
            <meta
              property="og:description"
              content={`Watch the live stream of ${featureName}, explore breathtaking locations in ${localFeature?.properties?.wikidata || 'this location'}, and discover more incredible live streams from around the world on Earf.`}
            />
            <meta property="og:image" content={videoThumbnail} />
            <meta property="og:image:alt" content={`${featureName} thumbnail`} />
            <meta property="og:url" content={`https://earf.tv/stream/${videoId}`} />
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:title" content={`${featureName} Stream | Earf`} />
            <meta
              name="twitter:description"
              content={`Watch the live stream of ${featureName} and explore more live streams from stunning places around the world on Earf.`}
            />
            <meta name="twitter:image" content={videoThumbnail} />
          </Helmet>
        </>
      )}

      <div className="video-view">
        <div className="main-video-content">
          <div className="video-container">
            <iframe
              src={`https://www.youtube.com/embed/${videoId}?autoplay=1&mute=0`}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              onLoad={handleVideoPlay}
            ></iframe>
          </div>

          <div className="video-info">
            {localFeature && (
              <div className="info-container">
                <div className="info-left">
                  <div className="channel-info">
                    <img
                      id="channel-thumbnail"
                      src={localFeature.properties.channelThumbnail} // Using feature property for channel thumbnail
                      alt={localFeature.properties.channelName || 'Channel Thumbnail'}
                    />
                    <div className="channel-details">
                      <h2 className="feature-title">{featureName}</h2>
                      <h4 id="channel-name">
                        <a
                          href={`https://www.youtube.com/channel/${localFeature.properties.channelId}`} // Using feature property for channel link
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {localFeature.properties.channelName} {/* Using feature property for channel name */}
                        </a>
                      </h4>
                      <p style={{ color: localFeature?.properties?.website ? 'inherit' : 'grey' }}>
                        {localFeature?.properties?.website ? (
                          <a
                            href={
                              localFeature.properties.website.startsWith('http://') ||
                                localFeature.properties.website.startsWith('https://')
                                ? localFeature.properties.website
                                : `https://${localFeature.properties.website}`
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                            className="website-link"
                          >
                            Official Website
                          </a>
                        ) : (
                          'No Website Available'
                        )}
                      </p>

                      <p className="wikidataText">
                        {`${localFeature?.properties?.wikidata}`}
                      </p>

                      <div className="feature-tags">
                        {[localFeature?.properties?.tag1, localFeature?.properties?.tag2, localFeature?.properties?.tag3]
                          .filter((tag) => tag)
                          .map((tag, index) => (
                            <span
                              key={index}
                              className="feature-tag"
                              onClick={() => handleTagClick(tag)}
                            >
                              {tag}
                            </span>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="info-right">
                  {coordinates && (
                    <div className="location-info-container">
                      {/* LocationInfo component */}
                      <LocationInfo coordinates={coordinates} />

                      {/* Button placed under LocationInfo */}
                      <div className="navigate-to-map-container">
                        <button
                          onClick={() => handleFeatureClick(coordinates[1], coordinates[0])} // Passing latitude and longitude
                          className="navigate-to-map-button"
                        >
                          <i className="fas fa-map-marker-alt"></i> View on Map
                        </button>
                      </div>
                    </div>
                  )}
                </div>

              </div>
            )}
          </div>

          <StreamDescription qid={placeQid} countryCode={countryCode} />

        </div>

        <SimilarVideosSidebar
          wikidata={localFeature?.properties?.wikidata}
          tags={[
            localFeature?.properties?.tag1,
            localFeature?.properties?.tag2,
            localFeature?.properties?.tag3,
          ].filter(Boolean)}
          currentVideoId={localFeature?.properties?.videoId}
        />
      </div>
    </>
  );
};

export default VideoView;
