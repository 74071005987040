import React, { useEffect, useState } from 'react';
import styles from './ServerLog.module.css'; // Import the CSS module

const ServerLog = () => {
  const [logs, setLogs] = useState([]); // Stores all logs
  const [filteredLogs, setFilteredLogs] = useState([]); // Stores logs filtered by tab
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState('ALL'); // Current active tab

  const formatRelativeTime = (timestamp) => {
    const logTime = new Date(timestamp);
    const now = new Date();
    const diffInSeconds = Math.floor((now - logTime) / 1000);

    if (diffInSeconds < 60) return `${diffInSeconds} seconds ago`;
    if (diffInSeconds < 3600) return `${Math.floor(diffInSeconds / 60)} minutes ago`;
    if (diffInSeconds < 86400) return `${Math.floor(diffInSeconds / 3600)} hours ago`;
    return `${Math.floor(diffInSeconds / 86400)} days ago`;
  };

  const parseLog = (log) => {
    const match = log.match(/^\[(.*?)\]\s\[(.*?)\]\s(.*)$/); // Matches [timestamp] [type] message
    if (match) {
      const [_, timestamp, type, message] = match;
      return { timestamp, type, message };
    }
    return { timestamp: null, type: 'UNKNOWN', message: log };
  };

  useEffect(() => {
    let ws;

    const initializeWebSocket = () => {
      const apiKey = 'EARFPULSE'; // Replace with your actual API key
      const token = sessionStorage.getItem('adminToken'); // Retrieve the stored admin token

      if (!token) {
        setError('No admin session found. Please log in.');
        return;
      }

      ws = new WebSocket(`wss://server.earf.tv/consolelog?x-api-key=${apiKey}&token=${token}`);

      ws.onopen = () => {
        console.log('WebSocket connection established');
      };

      ws.onmessage = (event) => {
        const message = event.data;
        setLogs((prevLogs) => {
          const newLogs = [...prevLogs, message];
          // Apply the current tab filter to new logs
          applyFilter(newLogs, activeTab);
          return newLogs;
        });
      };

      ws.onerror = (err) => {
        console.error('WebSocket error:', err);
        setError('Error connecting to WebSocket');
      };

      ws.onclose = () => {
        console.log('WebSocket connection closed');
      };
    };

    const timeoutId = setTimeout(() => {
      initializeWebSocket();
    }, 5000);

    return () => {
      clearTimeout(timeoutId);
      if (ws) {
        ws.close();
      }
    };
  }, []); // Only run once, when the component mounts

  useEffect(() => {
    // Reapply filter when activeTab changes
    applyFilter(logs, activeTab);
  }, [activeTab, logs]); // Reapply the filter when logs or activeTab changes

  const applyFilter = (logs, tab) => {
    if (tab === 'ALL') {
      setFilteredLogs(logs);
    } else {
      setFilteredLogs(logs.filter((log) => log.includes(`[${tab}]`)));
    }
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  if (error) {
    return <div className={styles.error}>{error}</div>;
  }

  return (
    <div className={styles.serverLogContainer}>
      <div className={styles.serverLogHeader}>
        <h2 className={styles.title}>Server Log</h2>
        <p className={styles.serverUrl}>server.earf.tv</p>
      </div>
      <div className={styles.tabs}>
        {['ALL', 'LOGIN ATTEMPT', 'UPDATER', 'CREATER', 'DELETER', 'SITEMAP'].map((tab) => (
          <button
            key={tab}
            className={`${styles.tabButton} ${activeTab === tab ? styles.activeTab : ''}`}
            onClick={() => handleTabChange(tab)}
          >
            {tab}
          </button>
        ))}
      </div>
      <div className={styles.logContainer}>
        <table className={styles.table}>
          <tbody>
            {filteredLogs
              .slice()
              .reverse()
              .map((log, index) => {
                const { timestamp, type, message } = parseLog(log);
                return (
                  <tr key={index} className={styles.row} data-log-type={type}>
                    <td>
                      <span className={styles.timestamp}>
                        {timestamp ? formatRelativeTime(timestamp) : 'Unknown time'}
                      </span>
                      <span className={styles.message}>{message}</span>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ServerLog;
