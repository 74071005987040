import React from 'react';
import { useNavigate } from 'react-router-dom';
import './PrivacyPolicy.css'; // CSS file for styling
import { Helmet } from 'react-helmet-async';

const PrivacyPolicy = () => {
    const navigate = useNavigate();

    return (
        <div className="privacy-policy-container">

<Helmet prioritizeSeoTags>
  {/* Page Title */}
  <title>Privacy Policy | Earf</title>

  {/* Meta Description */}
  <meta
    name="description"
    content="Discover how Earf collects, uses, and safeguards your personal information while you explore live-streams of Earth's beauty and real-time global data. Read our comprehensive Privacy Policy."
  />

  {/* Canonical Tag */}
  <link rel="canonical" href="https://earf.tv/privacy-policy" />

  {/* Open Graph Meta Tags */}
  <meta property="og:title" content="Privacy Policy | Earf" />
  <meta
    property="og:description"
    content="Learn about Earf's commitment to protecting your privacy, including how we collect, use, and safeguard your personal data while you explore live-streams of Earth's beauty."
  />
  <meta property="og:image" content="https://earf.tv/metaimage.png" />
  <meta property="og:url" content="https://earf.tv/privacy-policy" />
  <meta property="og:type" content="website" />
  <meta property="og:image:width" content="1200" />
  <meta property="og:image:height" content="630" />

  {/* Twitter Card Meta Tags */}
  <meta name="twitter:card" content="summary" />
  <meta name="twitter:title" content="Privacy Policy | Earf" />
  <meta
    name="twitter:description"
    content="Find out how Earf handles your personal information, ensuring your privacy is protected while using our live-streaming platform featuring Earth's beauty and real-time data."
  />
  <meta name="twitter:image" content="https://earf.tv/metaimage.png" />
  <meta name="twitter:image:alt" content="Earf Privacy Policy" />
</Helmet>


            {/* Header with Back Button (fixed at the top) */}
            <div className="header-container">
                <h1 className="privacy-header">Privacy Policy</h1>
                <button className="back-button" onClick={() => navigate(-1)}>
                    Back
                </button>
            </div>

            {/* Scrollable content section */}
            <div className="privacy-content">
                <p style={{ fontSize: '16px' }}>
                    <strong>Effective Date:</strong> October 2024
                </p>

                {/* Privacy Policy */}
                <p>
                    Earf, through its platform <strong>Earf.TV</strong>, previously known as <strong>Interactive.Earth</strong>, is committed to protecting your privacy. This Privacy Policy outlines how we collect, use, and safeguard your personal information when you interact with the Website.
                </p>

                <p>
                    References to "we," "us," and "our" refer to <strong>Earf</strong>, <strong>Earf.TV</strong>, and <strong>Interactive.Earth</strong>, including their subdomains and related services. By using the Website, you agree to the data practices described in this policy.
                </p>

                <p>
                    By accessing or using <strong>Earf</strong> (the "Website"), including any domains or subdomains associated with <strong>Earf.TV</strong> and <strong>Interactive.Earth</strong>, you confirm that you have read, understood, and agreed to this Privacy Policy. If you do not agree, please discontinue your use of the Website immediately.
                </p>

                <h3>Information We Collect</h3>
                <p>We collect the following information:</p>
                <ul>
                    <li>IP address</li>
                    <li>Browser information</li>
                    <li>Usage data</li>
                    <li>Device and location data (as described in "Use of Device and Location Data" below)</li>
                </ul>

                <h3>How We Use Your Information</h3>
                <p>We collect and use this information for the following purposes:</p>
                <ul>
                    <li>To provide and maintain our services</li>
                    <li>To improve user experience</li>
                    <li>For marketing and promotional endeavors, including Google AdSense</li>
                </ul>

                <h3>How We Protect Your Information</h3>
                <p>We employ robust SSL encryption and secure databases to ensure the safety and security of your data.</p>

                <h3>Use of Device and Location Data</h3>
                <p>In cases where we collect, process, or disclose information that can pinpoint or reasonably infer an end user's precise geographic location, sourced from GPS, Wi-Fi, or cell tower data, we prioritize the utmost care and transparency in our practices:</p>
                <ul>
                    <li>We explicitly notify users, through an interstitial or just-in-time notice, about the intended purposes for utilizing their data (including ad personalization, analytics, and attribution, as applicable), and clarify that the data may be shared with our partners, including Mapbox and Google, in an encrypted state.</li>
                    <li>We obtain express, opt-in consent from end users before collecting, processing, or disclosing such information. This consent explicitly covers the transmission of data to Mapbox and Google in an encrypted format.</li>
                    <li>It's important to note that once this information is transmitted to Mapbox and Google in an encrypted manner, our involvement ceases. We do not track, retain, or store this information on our servers or systems.</li>
                    <li>Our commitment to user privacy extends to ensuring that the transmission of such information to Mapbox and Google occurs solely via secure encrypted channels, maintaining the encrypted state throughout the process and preventing any tracking or retention on our end.</li>
                    <li>We provide comprehensive disclosure within all pertinent privacy policies, affirming that this information collection, processing, or disclosure does not involve tracking or retention on our end. Users can trust that their location data, once securely transmitted for specific purposes, is not stored or monitored by our systems.</li>
                </ul>

                <h3>Google Analytics</h3>
                <p>Earf utilizes Google Analytics, a web analytics service provided by Google, Inc. Google Analytics uses cookies to help analyze how users interact with our website. The information generated by the cookie about your use of Earf.TV or Interactive.Earth (including your IP address) will be transmitted to and stored by Google on servers in the United States.</p>
                <p>This information is used to evaluate visitors' use of the website, compile reports on website activity, and provide other services related to website activity and internet usage. Google may also transfer this information to third parties where required to do so by law or where such third parties process the information on Google's behalf.</p>
                <p>By using Earf.TV or Interactive.Earth, you consent to the processing of data about you by Google in the manner and for the purposes set out above.</p>
                <p>For more information on how Google Analytics collects and processes data, as well as how you can control the information sent to Google, please review Google's Privacy Policy for Partner Sites.</p>

                <h3>YouTube API</h3>
                <p>Earf utilizes the YouTube API to provide video content and related services on our platforms. By using our services, you agree to be bound by the YouTube Terms of Service. The YouTube API collects and processes the following data:</p>
                <ul>
                    <li>YouTube video IDs and metadata</li>
                    <li>User interactions with embedded YouTube videos (e.g., play, pause, like, etc.)</li>
                    <li>Video likes</li>
                    <li>Live viewers</li>
                    <li>Thumbnails</li>
                    <li>IP address and device information when accessing YouTube content</li>
                </ul>
                <p>We use this information to:</p>
                <ul>
                    <li>Provide and enhance video content and services on our platforms</li>
                    <li>Analyze user interactions with YouTube videos to improve our offerings</li>
                </ul>
                <p>We adhere to the YouTube API Services Terms of Service and Google's Privacy Policy. For more information on how YouTube collects and processes data, please visit the <a href="https://policies.google.com/privacy" target="_blank">Google Privacy Policy</a>.</p>
                <p>If you wish to revoke access to your data via the YouTube API, you can do so by visiting the <a href="https://security.google.com/settings/security/permissions" target="_blank">Google security settings page</a>.</p>

                <h3>Google AdSense</h3>
                <p>Earf may use Google AdSense to display advertisements on our platforms. Google AdSense may use cookies to personalize the content and ads you see, provide social media features, and analyze our traffic. Google may also share information about your use of our platforms with social media, advertising, and analytics partners.</p>

                <h3>Data Sharing and Disclosure</h3>
                <p>We do not sell, trade, or otherwise transfer your personally identifiable information to outside parties. This does not include trusted third parties who assist us in operating our website or servicing you, so long as those parties agree to keep this information confidential. We may share your information with trusted partners who assist us in providing our services. These third parties have agreed to keep your information confidential and secure.</p>

                <h3>Cookies and Tracking Technologies</h3>
                <p>We may use cookies and similar tracking technologies to improve your experience on our platforms. You can manage your cookie preferences through your browser settings.</p>

                <h3>International Data Transfers</h3>
                <p>In all cases where we transfer data internationally, we implement appropriate safeguards to protect your data, such as standard contractual clauses approved by regulatory bodies.</p>

                <h3>Opt-Out Options</h3>
                <p>You can opt out of data collection or targeted advertising by adjusting your browser settings or preferences. For targeted advertising, you can also opt out through the <a href="https://optout.networkadvertising.org/?c=1" target="_blank">NAI Opt-Out page</a>.</p>

                <h3>User Rights</h3>
                <p>You have the right to access, correct, delete, or restrict the processing of your personal information. If you have any concerns about your privacy or data security, please contact us using the information provided below.</p>

                <h3>CPRA Compliance</h3>
                <ul>
                    <li><strong>Right to Opt-Out:</strong> California residents have the right to opt out of the sale of their personal data. However, please note that Earf does not and will not sell personal data to third parties.</li>
                    <li><strong>Right to Access and Delete:</strong> You have the right to request access to your personal data and request its deletion, subject to certain exceptions.</li>
                    <li><strong>Right to Know and Correct:</strong> California residents have the right to know what personal data we collect and why, as well as the right to correct inaccurate data.</li>
                    <li><strong>Right to Data Portability:</strong> You have the right to request your data in a portable format.</li>
                    <li><strong>Non-Discrimination:</strong> We do not discriminate against users who exercise their rights under the CPRA.</li>
                </ul>

                <h3>GDPR Compliance</h3>
                <ul>
                    <li><strong>Purpose and Legal Basis for Data Processing:</strong> We process your personal data based on your consent, our legitimate interests, and our contractual obligations.</li>
                    <li><strong>Data Subject Rights:</strong> As a data subject, you have the right to access, correct, delete, or restrict the processing of your personal data.</li>
                    <li><strong>Data Retention:</strong> We retain your data only for as long as necessary to fulfill the purposes outlined in this policy, unless a longer retention period is required by law.</li>
                    <li><strong>Data Transfers:</strong> If we transfer your data outside the EU/EEA, we ensure appropriate safeguards are in place to protect your data in accordance with GDPR standards.</li>
                </ul>

                <h3>LGPD Compliance</h3>
                <ul>
                    <li><strong>Purpose and Legal Basis for Data Processing:</strong> We process your data based on the legal bases specified in the LGPD, including consent, legitimate interest, and contractual necessity.</li>
                    <li><strong>Data Subject Rights:</strong> You have the right to access, correct, delete, or anonymize your personal data. You may also have the right to data portability and the right to revoke consent.</li>
                    <li><strong>Data Transfers:</strong> When we transfer data outside Brazil, we take steps to ensure adequate safeguards are in place to protect your data.</li>
                </ul>

                <h3>Policy Modification</h3>
                <p>This policy may be revised periodically. Users will be notified of any alterations through an updated effective date at the top of this page.</p>

                <h3>Contact Us</h3>
                <p>If you have any inquiries regarding this Privacy Policy, kindly reach out to us at <a href="mailto:legal@earf.tv">legal@earf.tv</a>, or visit our Help and Contact page for further assistance.</p>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
