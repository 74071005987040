import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom'; // Use Link for navigation
import './CountryRow.css';

const countries = [
    { id: 1, name: 'Australia', code: 'au' },
    { id: 2, name: 'Brazil', code: 'br' },
    { id: 3, name: 'France', code: 'fr' },
    { id: 4, name: 'Germany', code: 'de' },
    { id: 5, name: 'Italy', code: 'it' },
    { id: 6, name: 'Japan', code: 'jp' },
    { id: 7, name: 'Mexico', code: 'mx' },
    { id: 8, name: 'Netherlands', code: 'nl' },
    { id: 9, name: 'South Korea', code: 'kr' },
    { id: 10, name: 'Taiwan', code: 'tw' },
    { id: 11, name: 'Thailand', code: 'th' },
    { id: 12, name: 'United States', code: 'us' }
];

const CountryRow = () => {
    const [visibleCountries, setVisibleCountries] = useState(countries.length); // Start by showing all
    const countryRowRef = useRef(null);
    const [loadedImages, setLoadedImages] = useState({}); // Track which images are loaded

    // Calculate how many countries can fit in the current container width
    const calculateVisibleCountries = () => {
        if (countryRowRef.current) {
            const containerWidth = countryRowRef.current.offsetWidth;
            const itemWidth = 180;
            const visibleItems = Math.floor(containerWidth / itemWidth);
            setVisibleCountries(visibleItems);
        }
    };

    useEffect(() => {
        calculateVisibleCountries();
        window.addEventListener('resize', calculateVisibleCountries);

        // Cleanup event listener on unmount
        return () => window.removeEventListener('resize', calculateVisibleCountries);
    }, []);

    // Handle image load to remove the blur
    const handleImageLoad = (countryId) => {
        setLoadedImages((prevLoadedImages) => ({
            ...prevLoadedImages,
            [countryId]: true,
        }));
    };

    return (
        <div className="country-section-wrapper">
            <div className="country-header-wrapper">
                <h2 className="country-row-title">
                    <span className="earf-blue">Top</span> Live Countries
                </h2>
                <Link to="/browse" className="country-view-all-btn">
                    View All
                </Link>
            </div>
            <div className="country-row-wrapper" ref={countryRowRef}>
                {countries.slice(0, visibleCountries).map((country) => {
                    const formattedName = country.name
                        .split(' ')
                        .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                        .join('-');

                    return (
                        <Link
                            to={`/browse/${formattedName}`}
                            key={country.id}
                            className="country-item-wrapper"
                            style={{ textDecoration: 'none', color: 'inherit', cursor: 'pointer' }}
                        >
                            <div className="country-flag-wrapper">
                                <img
                                    src={`https://flagcdn.com/w320/${country.code}.png`}
                                    alt={country.name}
                                    className={`country-flag ${loadedImages[country.id] ? 'loaded' : 'loading'}`}
                                    loading="lazy"
                                    onLoad={() => handleImageLoad(country.id)}
                                />
                                {/* Blur effect placeholder */}
                                {!loadedImages[country.id] && <div className="image-placeholder"></div>}
                            </div>
                            <span className="country-name">{country.name}</span>
                        </Link>
                    );
                })}
            </div>
        </div>
    );
};

export default CountryRow;
