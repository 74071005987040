import React, { useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Wildfires.css'; // Custom styling for wildfires

const Wildfires = ({ handleWildfireLocationSelect }) => { 
  const [wildfireList, setWildfireList] = useState([]);
  const [loading, setLoading] = useState(true); 
  const [initialLoad, setInitialLoad] = useState(true); 
  const [latestWildfireId, setLatestWildfireId] = useState(null); 
  const intervalRef = useRef(null); 

  // Fetch Wildfire data from NASA EONET
  const fetchWildfireData = async () => {
    setLoading(true);
    try {
      const response = await fetch('https://eonet.gsfc.nasa.gov/api/v3/events?category=wildfires');
      const data = await response.json();
      const wildfires = data.events;

      if (wildfires.length === 0) {
        setLoading(false);
        return;
      }

      const latestWildfire = wildfires[0]; 

      if (!initialLoad && latestWildfire.id !== latestWildfireId) {
        toast.warn('New Wildfire Detected!', {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
        });
        setLatestWildfireId(latestWildfire.id);
      }

      setWildfireList(wildfires);
      setLoading(false);
      setInitialLoad(false); 
    } catch (error) {
      console.error('Error fetching wildfire data:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchWildfireData();

    intervalRef.current = setInterval(() => {
      fetchWildfireData();
    }, 900000);

    return () => clearInterval(intervalRef.current);
  }, []);

  // Helper function to format the date
  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString();
  };

  const handleViewOnMap = (geometry) => {
    if (geometry.length > 0) {
      const [longitude, latitude] = geometry[0].coordinates;
      handleWildfireLocationSelect(latitude, longitude, 15); 
    }
  };

  // Render the loader
  const renderLoader = () => (
    <div className="full-container">
    <div className="spinner-wrapper">
      <div className="spinner"></div> {/* Spinner Element */}
    </div>
  </div>
  );

  // Render the list of wildfires
  const renderWildfireList = () => (
    <div className="wildfire-list">
      {wildfireList.map((wildfire, index) => (
        <div key={index} className="wildfire-card">
          <div className="wildfire-card-content">
            <div className="wildfire-info">
              <h3 className="wildfire-title">
                {wildfire.title} | <span style={{ color: 'var(--earf-blue)' }}> {wildfire.description || 'No description available'}</span>
              </h3>
              <p className="wildfire-time">
                {wildfire.closed ? `Closed: ${formatDate(wildfire.closed)}` : 'Ongoing'}
              </p>
            </div>
            <button 
              className="wildfire-map-button" 
              onClick={() => handleViewOnMap(wildfire.geometry)}
            >
              View on Map
            </button>
          </div>
        </div>
      ))}
      {loading && renderLoader()}
    </div>
  );

  return (
    <div>
      <div className="wildfire-header">
        <h2>Wildfire <span style={{ color: 'var(--earf-blue)' }}>Alerts</span></h2>
      </div>
      {renderWildfireList()}
    </div>
  );
};

export default Wildfires;
