// PlayerContext.js
import React, { createContext, useContext, useState } from 'react';

const PlayerContext = createContext();

export const PlayerProvider = ({ children }) => {
  const [currentStation, setCurrentStation] = useState(null);

  const playStation = (url, favicon, name) => {
    setCurrentStation({
      url,
      favicon,
      name,
      isPlaying: true,
    });
  };

  const stopStation = () => {
    setCurrentStation((prevStation) => ({
      ...prevStation,
      isPlaying: false,
    }));
  };

  return (
    <PlayerContext.Provider value={{ currentStation, playStation, stopStation }}>
      {children}
    </PlayerContext.Provider>
  );
};

export const usePlayerContext = () => {
  return useContext(PlayerContext);
};
