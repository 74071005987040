import React, { useState, useEffect } from 'react';
import styles from './Facts.module.css';
import { FaCalendarAlt } from 'react-icons/fa'; // Importing calendar icon

const Facts = () => {
  const [event, setEvent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const apiKey = process.env.REACT_APP_EARF_API_KEY;
  const charLimit = 75; // Set a default character limit

  const fetchRandomEvent = async () => {
    setLoading(true);
    setError(null);

    const today = new Date();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');

    try {
      const response = await fetch(
        `https://server.earf.tv/facts/date/${month}/${day}?charLimit=${charLimit}`,
        {
          headers: {
            'x-api-key': apiKey,
            'Content-Type': 'application/json',
            'X-Request-Source': 'Facts',
          },
        }
      );

      if (!response.ok) {
        throw new Error('Failed to fetch random event.');
      }

      const data = await response.json();
      setEvent(data.data || null);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRandomEvent();
  }, [apiKey, charLimit]);

  const handleIconClick = () => {
    fetchRandomEvent(); // Fetch another random event when the calendar icon is clicked
  };
  const addTargetBlankToLinks = (html) => {
    const div = document.createElement('div');
    div.innerHTML = html;
    const links = div.querySelectorAll('a');
    links.forEach((link) => {
      link.target = '_blank';
      link.rel = 'noopener noreferrer'; // Security best practice
    });
    return div.innerHTML;
  };

  return (
    <div
      className={styles['facts-container']}
      style={{ visibility: loading ? 'hidden' : 'visible' }} // Hide until fully loaded
    >
      {loading ? (
        <div className={styles['spinner-wrapper']}>
          <div className={styles.spinner}></div>
        </div>
      ) : error ? (
        <p className={styles.error}>{error}</p>
      ) : event ? (
        <div className={styles.fact}>
          <div className={styles.header}>
            <h1 className={styles.title}>Today in History</h1>
            <span
              className={styles.year}
              onClick={handleIconClick} // Add onClick handler to the calendar icon
              style={{ cursor: 'pointer' }} // Change cursor to pointer for better UX
              title="Click for another fact" // Add tooltip for clarity
            >
              {event.year} <FaCalendarAlt className={styles.icon} />
            </span>
          </div>
          <p
            className={styles.text}
            dangerouslySetInnerHTML={{ __html: addTargetBlankToLinks(event.no_year_html) }}
          ></p>

        </div>
      ) : (
        <p className={styles.text}>No events found for today.</p>
      )}
    </div>
  );
};

export default Facts;
