// KnownAsTags.js
import React, { useState, useEffect } from 'react';
import styles from './KnownAsTags.module.css';

const KnownAsTags = ({ qid }) => {
  const [aliases, setAliases] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchAliases = async () => {
      setIsLoading(true);

      try {
        const response = await fetch(
          `https://www.wikidata.org/w/api.php?action=wbgetentities&ids=${qid}&props=aliases&languages=en&format=json&origin=*`
        );
        const data = await response.json();

        if (data.entities && data.entities[qid]?.aliases?.en) {
          const knownAsNames = data.entities[qid].aliases.en.map(alias => alias.value);
          setAliases(knownAsNames);
        } else {
          setAliases([]);
        }
      } catch (error) {
        console.error("Error fetching aliases:", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (qid) {
      fetchAliases();
    }
  }, [qid]);

  // If there are no aliases and loading is complete, do not render the component
  if (!isLoading && aliases.length === 0) {
    return null;
  }

  return (
    <div className={styles.knownAsContainer}>
      <h3>Also known as</h3>
      {isLoading ? (
        <p></p>
      ) : (
        <div className={styles.tagsContainer}>
          {aliases.map((alias, index) => (
            <span key={index} className={styles.tag}>
              {alias}
            </span>
          ))}
        </div>
      )}
    </div>
  );
};

export default KnownAsTags;
