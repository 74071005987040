import React, { useEffect, useState } from 'react';
import styles from './PlaceInfoBar.module.css';

const PlaceInfoBar = ({ wikidata }) => {
  const [weather, setWeather] = useState(null);
  const [airQuality, setAirQuality] = useState(null);
  const [clockTime, setClockTime] = useState(null);
  const [initialTime, setInitialTime] = useState(null);

  const EARF_API_KEY = process.env.REACT_APP_EARF_API_KEY;

  useEffect(() => {
    if (wikidata) {
      fetchLocationData(wikidata);
    }
  }, [wikidata]);

  useEffect(() => {
    if (initialTime) {
      const intervalId = setInterval(() => {
        const updatedTime = new Date(initialTime.getTime() + 1000);
        setInitialTime(updatedTime);
        updateClockDisplay(updatedTime);
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [initialTime]);

  const updateClockDisplay = (currentTime) => {
    const time = currentTime.toLocaleTimeString([], {
      hour: 'numeric',
      minute: '2-digit',
      second: '2-digit',
      hour12: true,
    });
    setClockTime(time);
  };

  const fetchLocationData = async (placeName) => {
    try {
      // Fetch coordinates from backend (POST with body)
      const coordinatesResponse = await fetch(
        `https://server.earf.tv/location/coordinates`,
        {
          method: 'POST',
          headers: {
            'x-api-key': EARF_API_KEY,
            'Content-Type': 'application/json',
            'X-Request-Source': 'Video-Section-Coordinates',
          },
          body: JSON.stringify({ placeName }), // Send placeName in body
        }
      );
      if (!coordinatesResponse.ok) {
        throw new Error('Failed to fetch coordinates');
      }
      const { lat, lon } = await coordinatesResponse.json();

      // Fetch weather data from backend
      const weatherResponse = await fetch(
        `https://server.earf.tv/location/weather`,
        {
          method: 'POST',
          headers: {
            'x-api-key': EARF_API_KEY,
            'Content-Type': 'application/json',
            'X-Request-Source': 'Video-Section-Weather',
          },
          body: JSON.stringify({ lat, lon }), // Send coordinates in body
        }
      );
      if (!weatherResponse.ok) {
        throw new Error('Failed to fetch weather data');
      }
      const weatherData = await weatherResponse.json();
      setWeather({
        temp: weatherData.temperature,
        status: capitalizeFirstLetter(weatherData.description),
        icon: weatherData.icon,
      });

      // Fetch time data from backend
      const timeResponse = await fetch(
        `https://server.earf.tv/location/time`,
        {
          method: 'POST',
          headers: {
            'x-api-key': EARF_API_KEY,
            'Content-Type': 'application/json',
            'X-Request-Source': 'Video-Section-Time',
          },
          body: JSON.stringify({ lat, lon }), // Send coordinates in body
        }
      );
      if (!timeResponse.ok) {
        throw new Error('Failed to fetch time data');
      }
      const timeData = await timeResponse.json();
      const fetchedTime = new Date(timeData.formattedTime);
      setInitialTime(fetchedTime);

      // Fetch air quality data from backend
      const airQualityResponse = await fetch(
        `https://server.earf.tv/location/airquality`,
        {
          method: 'POST',
          headers: {
            'x-api-key': EARF_API_KEY,
            'Content-Type': 'application/json',
            'X-Request-Source': 'Video-Section-AirQuality',
          },
          body: JSON.stringify({ lat, lon }), // Send coordinates in body
        }
      );
      if (!airQualityResponse.ok) {
        throw new Error('Failed to fetch air quality data');
      }
      const airQualityData = await airQualityResponse.json();
      const aqi = airQualityData.aqi;
      setAirQuality(mapAQIToLabel(aqi));
    } catch (error) {
      console.error('Error fetching location data:', error);
    }
  };

  const mapAQIToLabel = (aqi) => {
    switch (aqi) {
      case 1:
        return 'Good';
      case 2:
        return 'Fair';
      case 3:
        return 'Moderate';
      case 4:
        return 'Poor';
      case 5:
        return 'Very Poor';
      default:
        return 'Unknown';
    }
  };

  const capitalizeFirstLetter = (text) => {
    return text.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  return (
    <div className={styles['place-info-bar']}>
      {clockTime && (
        <span className={styles['place-info-item']}>
          🕒 {clockTime}
        </span>
      )}

      {weather && (
        <span className={styles['place-info-item']}>
          <img src={weather.icon} alt={weather.status} className={styles['weather-icon']} />
          {weather.temp}°C - {weather.status}
        </span>
      )}

      {airQuality && <span className={styles['place-info-item']}>🌫️ Air Quality: {airQuality}</span>}
    </div>
  );
};

export default PlaceInfoBar;
