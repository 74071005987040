import React, { createContext, useState, useEffect } from 'react';

// Create a context
export const AdminContext = createContext();

// Provide context to all components
export const AdminProvider = ({ children }) => {
  const [isAdminAuthenticated, setIsAdminAuthenticated] = useState(
    JSON.parse(sessionStorage.getItem('isAdminAuthenticated')) || false
  ); // Initialize with sessionStorage value
  const [adminToken, setAdminToken] = useState(sessionStorage.getItem('adminToken') || null); // Initialize with sessionStorage token
  const [isInitialized, setIsInitialized] = useState(false); // State to manage initialization

  useEffect(() => {
    // Sync with sessionStorage on first load
    const storedAuthState = JSON.parse(sessionStorage.getItem('isAdminAuthenticated')) === true;
    const storedToken = sessionStorage.getItem('adminToken');
    setIsAdminAuthenticated(storedAuthState);
    setAdminToken(storedToken);
    setIsInitialized(true); // Mark as initialized
  }, []);

  const loginAdmin = (token) => {
    setIsAdminAuthenticated(true);
    setAdminToken(token); // Save token to state
    sessionStorage.setItem('isAdminAuthenticated', true);
    sessionStorage.setItem('adminToken', token); // Store token in sessionStorage
  };

  const logoutAdmin = () => {
    setIsAdminAuthenticated(false);
    setAdminToken(null); // Clear token from state
    sessionStorage.removeItem('isAdminAuthenticated');
    sessionStorage.removeItem('adminToken'); // Remove token from sessionStorage
  };

  if (!isInitialized) {
    // Optional: Return null instead of a loader to prevent blocking UI
    return null;
  }

  return (
    <AdminContext.Provider value={{ isAdminAuthenticated, adminToken, loginAdmin, logoutAdmin }}>
      {children}
    </AdminContext.Provider>
  );
};
