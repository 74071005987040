import React, { useState, useEffect } from 'react';
import styles from './FeatureTags.module.css';

const FeatureTags = ({ qid }) => {
  const [countryFlag, setCountryFlag] = useState('');
  const [extraStats, setExtraStats] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (qid) {
      const fetchEntityDetails = async () => {
        try {
          setIsLoading(true);

          // Fetch data from Wikidata using QID
          const entityResponse = await fetch(
            `https://www.wikidata.org/w/api.php?action=wbgetentities&ids=${qid}&props=claims|sitelinks&format=json&origin=*`
          );
          const entityData = await entityResponse.json();
          const entity = entityData.entities?.[qid];

          if (!entity) {
            console.error('No entity data found for QID:', qid);
            return;
          }

          // Initialize stats array to store entity details
          const stats = [];

          // Country Flag (P297: ISO 3166-1 alpha-2 country code)
          const countryCode = entity.claims?.P297?.[0]?.mainsnak?.datavalue?.value;
          if (countryCode) {
            setCountryFlag(`https://flagcdn.com/256x192/${countryCode.toLowerCase()}.png`);
          }

          // Population (P1082)
          if (entity.claims?.P1082) {
            const population = entity.claims.P1082[0]?.mainsnak?.datavalue?.value?.amount;
            if (population) {
              stats.push({ label: 'Population', value: parseInt(population).toLocaleString() });
            }
          }

          // Area (P2046)
          if (entity.claims?.P2046) {
            const area = entity.claims.P2046[0]?.mainsnak?.datavalue?.value?.amount;
            if (area) {
              stats.push({ label: 'Area (km²)', value: parseFloat(area).toLocaleString() });
            }
          }

          // Elevation (P2044)
          if (entity.claims?.P2044) {
            const elevation = entity.claims.P2044[0]?.mainsnak?.datavalue?.value;
            if (elevation && typeof elevation === 'object' && elevation.amount) {
              stats.push({ label: 'Elevation (m)', value: elevation.amount.replace('+', '') });
            }
          }

          // GDP (P2131)
          if (entity.claims?.P2131) {
            const gdp = entity.claims.P2131[0]?.mainsnak?.datavalue?.value?.amount;
            if (gdp) {
              stats.push({ label: 'GDP (USD)', value: parseFloat(gdp).toLocaleString() });
            }
          }

          // Capital (P36)
          if (entity.claims?.P36) {
            const capitalEntityId = entity.claims.P36[0]?.mainsnak?.datavalue?.value?.id;
            if (capitalEntityId) {
              const capitalResponse = await fetch(
                `https://www.wikidata.org/w/api.php?action=wbgetentities&ids=${capitalEntityId}&props=labels&format=json&origin=*`
              );
              const capitalData = await capitalResponse.json();
              const capitalName = capitalData.entities[capitalEntityId]?.labels?.en?.value;
              if (capitalName) {
                stats.push({ label: 'Capital', value: capitalName });
              }
            }
          }

          // Official Language (P37)
          if (entity.claims?.P37) {
            const languageEntityId = entity.claims.P37[0]?.mainsnak?.datavalue?.value?.id;
            if (languageEntityId) {
              const languageResponse = await fetch(
                `https://www.wikidata.org/w/api.php?action=wbgetentities&ids=${languageEntityId}&props=labels&format=json&origin=*`
              );
              const languageData = await languageResponse.json();
              const languageName = languageData.entities[languageEntityId]?.labels?.en?.value;
              if (languageName) {
                stats.push({ label: 'Official Language', value: languageName });
              }
            }
          }

          // Currency (P38)
          if (entity.claims?.P38) {
            const currencyEntityId = entity.claims.P38[0]?.mainsnak?.datavalue?.value?.id;
            if (currencyEntityId) {
              const currencyResponse = await fetch(
                `https://www.wikidata.org/w/api.php?action=wbgetentities&ids=${currencyEntityId}&props=labels&format=json&origin=*`
              );
              const currencyData = await currencyResponse.json();
              const currencyName = currencyData.entities[currencyEntityId]?.labels?.en?.value;
              if (currencyName) {
                stats.push({ label: 'Currency', value: currencyName });
              }
            }
          }

          // Calling Code (P474)
          if (entity.claims?.P474) {
            const callingCode = entity.claims.P474[0]?.mainsnak?.datavalue?.value;
            if (callingCode) {
              stats.push({ label: 'Calling Code', value: `+${callingCode}` });
            }
          }

          // ISO Code (P297)
          if (countryCode) {
            stats.push({ label: 'ISO Code', value: countryCode });
          }

          // Time Zone (P421)
          if (entity.claims?.P421) {
            const timezoneEntityId = entity.claims.P421[0]?.mainsnak?.datavalue?.value?.id;
            if (timezoneEntityId) {
              const timezoneResponse = await fetch(
                `https://www.wikidata.org/w/api.php?action=wbgetentities&ids=${timezoneEntityId}&props=labels&format=json&origin=*`
              );
              const timezoneData = await timezoneResponse.json();
              const timezoneName = timezoneData.entities[timezoneEntityId]?.labels?.en?.value;
              if (timezoneName) {
                stats.push({ label: 'Time Zone', value: timezoneName });
              }
            }
          }

          // Continent (P30)
          if (entity.claims?.P30) {
            const continentEntityId = entity.claims.P30[0]?.mainsnak?.datavalue?.value?.id;
            if (continentEntityId) {
              const continentResponse = await fetch(
                `https://www.wikidata.org/w/api.php?action=wbgetentities&ids=${continentEntityId}&props=labels&format=json&origin=*`
              );
              const continentData = await continentResponse.json();
              const continentName = continentData.entities[continentEntityId]?.labels?.en?.value;
              if (continentName) {
                stats.push({ label: 'Continent', value: continentName });
              }
            }
          }

          // Set the retrieved stats
          setExtraStats(stats);
        } catch (error) {
          console.error('Error fetching entity details:', error);
        } finally {
          setIsLoading(false);
        }
      };

      fetchEntityDetails();
    }
  }, [qid]);


  return (
    <div className={styles.featureTags}>
      {isLoading ? (
        <p></p>
      ) : (
        (countryFlag || extraStats.length > 0) && (
          <div className={styles.inlineContainer}>
            {countryFlag && (
              <div className={styles.flagContainer}>
                <img src={countryFlag} alt="Country Flag" className={styles.flag} />
              </div>
            )}
            {extraStats.length > 0 && (
              <div className={styles.statsContainer}>
                {extraStats.map((stat, index) => (
                  <div key={index} className={styles.statTag}>
                    <strong>{stat.label}:</strong> {stat.value}
                  </div>
                ))}
              </div>
            )}
          </div>
        )
      )}
    </div>
  );
};

export default FeatureTags;
